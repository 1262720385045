import React from 'react'
import FooterNev from './FooterNev'

function Contact() {
    
  // scroll to top on page load
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    return (
        <div className="lg:container lg:mx-auto">
            <div className='py-6 px-2 lg:px-0'>
                <p className='font-light text-gray-600 text-3xl border-b-2'>Contact Us</p>
                <p className='font-normal text-justify leading-relaxed text-gray-600 text-xs py-4 h-screen'>

                </p>
            </div>
            <FooterNev />
        </div>
    )
}

export default Contact
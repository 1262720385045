import React, { useState } from 'react';


// const useStyles = makeStyles((theme) => ({
//     scrollbtn: {
//         position: 'sticky',
//         margin: '-100px 0px',
//         bottom: '40px',
//         fontSize: '3rem',
//         zIndex: '1',
//         cursor: 'pointer',
//         float: 'right',
//         '& > button': {
//             backgroundColor: '#37806B',
//             '& svg': {
//                 color: 'white',
//             },
//         },
//         '& > button:hover': {
//             backgroundColor: '#37806bde',
//         }
//     },
// }));

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className={visible ? 'inline sticky m-[-50px] mr-[24px] bottom-[50px] z-10 cursor-pointer float-right ' : 'hidden'}>
            <div onClick={scrollToTop} className="flex flex-col items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" className="w-7 h-7 p-[6px] text-white bg-[#d1d5db] rounded-full">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                <p className='font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-800 to-purple-800'>Top</p>
            </div>

        </div>
    );
}

export default ScrollButton;
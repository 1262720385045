import React, {useState} from "react";
import {Link} from "react-router-dom";
import info from "../assets/image/info.svg";
import lineShape from "../assets/image/line.png";
import play from "../assets/image/play.svg";
import user from "../assets/image/user.svg";
import edit from "../assets/image/edit.svg";
import love from "../assets/image/love.svg";
import shapeleft from "../assets/image/shapeleft.svg";
import shaperight from "../assets/image/shaperight.svg";
import station_1 from "../assets/image/station-1.png";
import {BASE_URL} from "./BaseURL";
import FooterNev from "./FooterNev";
import Favorite from "./Favorite";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import {UserContext} from "../App";
import AdsComponent from "./radioContainer/AdsComponent";
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function Account() {
    const userData = React.useContext(UserContext);
    const [data, setData] = React.useState([]);
    const [stations, setStations] = React.useState([]);
    const [isProfile, setIsProfile] = useState(true);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [isFavorite, setisFavorite] = useState(false);


    React.useEffect(() => {
        fetch(`${BASE_URL}/allcategories`)
            .then((res) => res.json())
            .then((data) => {
                setData(data.categories);
                setStations(data.stations);
            });
    }, []);

    const handleFabbar = () => {
        setisFavorite(true);
        setIsProfile(false);
        setIsEditProfile(false);
    }

    const handleView = () => {
        setisFavorite(false);
        setIsProfile(true);
        setIsEditProfile(false);
    }

    const handleEdit = () => {
        setisFavorite(false);
        setIsProfile(false);
        setIsEditProfile(true);
    }

    // scroll to top on page load
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="md:relative">
            <img
                className="invisible sm:visible absolute top-0 right-0 -z-50"
                src={shaperight}
                alt="shapeRight"
            />
            <img
                className="invisible sm:visible absolute top-0 left-0 -z-50"
                src={shapeleft}
                alt="shapeleft"
            />
            <img
                className="invisible sm:visible absolute bottom-0 right-0 -z-50"
                src={shaperight}
                alt="shapeRight"
            />
            <img
                className="invisible sm:visible absolute bottom-0 left-0 -z-50"
                src={shapeleft}
                alt="shapeleft"
            />

            <div className="lg:container lg:mx-auto">
                <div className="flex flex-col items-center space-y-6">
                    <p className="text-4xl font-semibold pt-12 pb-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                        My Account!
                    </p>
                    <img src={lineShape} alt="line"/>
                    <p className="text-center">
                        Stay connected to your favorite vibes <br></br>
                        and make your RadioGo Account more personal than ever!
                    </p>
                </div>
                <div className="flex flex-col space-y-6 pt-12 px-4">
                    <p className="font-normal text-lg md:text-xl text-center md:text-left self-center md:self-start w-[220px] md:w-full border-b border-y-rose-900 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                        Welcome {userData?.userData?.first_name}
                    </p>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-5">
                        <div
                            className="flex flex-row md:flex-col justify-center md:justify-start xs:col-span-12 sm:col-span-1 lg:col-span-2 md:col-span-2 gap-y-3 gap-x-3 z-20">
              <span class="flex">
                <img src={user} onClick={handleView} alt="user" className="w-[68px] h-[68px] rounded-full"/>
                  {isProfile && <span class=" absolute inline-flex h-4 w-4 rounded-full bg-red-400 opacity-75"></span>}
              </span>
                            <span class="flex">
                <img src={edit} onClick={handleEdit} alt="edit" className="w-[68px] h-[68px] rounded-full"/>
                                {isEditProfile && <span
                                    class=" absolute inline-flex h-4 w-4 rounded-full bg-red-400 opacity-75"></span>}
              </span>
                            <span class="flex">
                <img src={love} onClick={handleFabbar} alt="love" className="w-[68px] h-[68px] rounded-full"/>
                                {isFavorite && <span
                                    class=" absolute inline-flex h-4 w-4 rounded-full bg-red-400 opacity-75"></span>}
              </span>
                        </div>
                        {isProfile && <Profile/>}
                        {isEditProfile && <EditProfile/>}
                        {isFavorite && <Favorite/>}


                    </div>
                    <div className="adh w-2/3 h-24 md:block hidden self-center"  style={{display:"flex", justifyContent:'center'}}>
                        <AdsHorizontalComponent/>
                        {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
                    </div>
                </div>
                <FooterNev/>
            </div>
        </div>
    );
}

export default Account;

import React from "react";
import lineShape from "../assets/image/line.png";
import shapeleft from '../assets/image/shapeleft.svg';
import shaperight from '../assets/image/shaperight.svg';
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";
import  ReCAPTCHA  from "react-google-recaptcha";

function ContactUs() {
  const [showModal, setShowModal] = React.useState(false);
  const [allRegionsAndCategories, setAllRegionsAndCategories] = React.useState([]);
  const adminEmail = 'asifbiswas4512@gmail.com'
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

  // scroll to top on page load
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  React.useEffect(() => {
    fetch(`${BASE_URL}/allregcat`)
      .then((res) => res.json())
      .then((data) => {
        setAllRegionsAndCategories(data.categoriesAndRegions);
      });
  }, []);

  function onCapChange(value) {
    setIsCaptchaSuccess(true)
  }

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie('csrftoken');





  const handleSubmit = () => {

    if (formData.name === "") {
      alert("Please Enter Station Name");
      return;
    }
    if (formData.email === "") {
      alert("Please Enter Email");
      return;
    }
    if (formData.message === "") {
      alert("Please Enter Message");
      return;
    }
    window.location.href = `mailto:${adminEmail}?subject=Contact Us&body=Name: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0AMessage: ${formData.message}`;
  };


  return (
    <div className="md:relative font-DM_Sans">
      <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <div className="lg:container lg:mx-auto">
        <div className="px-2 lg:px-0 min-h-[85vh]">
          <div className="flex flex-col items-center pb-10 space-y-6">
            <p className="text-4xl font-semibold pt-12 pb-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
              Contact Us
            </p>
            <img src={lineShape} alt="line" />
          </div>
          <form className="md:flex justify-center">
            <div class="flex flex-col w-12/12 md:w-6/12 items-center py-2 px-3 rounded-2xl mb-4 bg-gray-200 ">
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Name<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Input Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Email<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Input your valid email"
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  value={formData.email}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Message<span className="text-red-500">*</span>
                </p>
                <textarea
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  name="message"
                  id="message"
                  placeholder="Input your valid message"
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  value={formData.message}
                />
              </div>
              <ReCAPTCHA
              sitekey={'6Ld2IZokAAAAAKYBd1X3vL17oEHWvg9Y7vuvUgSs'}
              onChange={onCapChange}
              />
              <div className="w-10/12 md:w-8/12 py-2 flex justify-center">
                <button disabled={!isCaptchaSuccessful} onClick={handleSubmit} type="button" className="text-base font-light py-1 text-white text-center bg-gradient-to-r from-red-700 to-purple-800 rounded-md p-2"
                // send mail
                
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
                  {/*body*/}
                  <div className="relative p-6 flex flex-col w-full h-80">
                    <p className="text-3xl font-medium py-4 text-transparent text-center bg-clip-text bg-gradient-to-r  from-red-700 to-purple-600">Add City/Region</p>
                    <div className="flex flex-col items-center gap-2 bg-gray-200 px-6 py-6">
                      <div class="flex justify-center gap-4 m-3 w-80">
                        <div class="form-check form-check-inlineg">
                          <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="category" />
                          <label class="form-check-label inline-block text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800" for="inlineRadio10">Category</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                          <label class="form-check-label inline-block text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800" for="inlineRadio20">Region</label>
                        </div>
                      </div>
                      <div class="form-group flex flex-col items-center justify-center">
                        <label class="inline-block text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800" for="name">Name</label>
                        <input className="rounded-b-lg py-2 px-2" type="text" name="inlineRadioOptions" id="name" />
                      </div>
                      <button className="px-4 py-2 text-center w-[80px] rounded-full text-white bg-gradient-to-r  from-red-700 to-purple-600">Add</button>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-1 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        <FooterNev />
      </div>
    </div>
  );
}

export default ContactUs;

import React from "react";
import Container from "./radioContainer/Container";
import NoAd from "./radioContainer/NoAd";

function Content({ title, gridSize, station, data }) {
  
  return (
    <>
      {/* loop the data object */}
      {Object.keys(data).map((item, index2) => {
        if (index2 === 0) {
          return (
            <NoAd 
              index2={index2}
              item={item}
              data={data}
            />
          )
        } else {
          return (
            <Container 
              index2={index2}
              item={item}
              data={data}
            />
          )
        }
      })}
    </>
  );
}

export default Content;

import React from "react";
import { Link } from "react-router-dom";
import login_image from '../assets/image/login_image.png';
import shapeleft from '../assets/image/shapeleft.svg';
import shaperight from '../assets/image/shaperight.svg';
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";
import SocialLogin from "./SocialLogin";


function Login() {
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [allRegionsAndCategories, setAllRegionsAndCategories] = React.useState([]);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  React.useEffect(() => {
    // check radio-remember-me cookie
    const rememberMeCookie = localStorage.getItem("radio-remember-me");
    if (rememberMeCookie) {
      setRememberMe(true);
      setFormData({
        ...formData,
        email: rememberMeCookie,
      });
    }
  }, []);

  React.useEffect(() => {
    fetch(`${BASE_URL}/allregcat`)
      .then((res) => res.json())
      .then((data) => {
        setAllRegionsAndCategories(data.categoriesAndRegions);
      });
  }, []);

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie('csrftoken');





  const handleSubmit = (e) => {
    e.preventDefault();
    if (rememberMe) {
      localStorage.setItem("radio-remember-me", formData.email);
    } else {
      localStorage.removeItem("radio-remember-me");
    }

    if (formData.email === "") {
      alert("Please Enter Email");
      return;
    }
    if (formData.password === "") {
      alert("Please Enter Password");
      return;
    }
    
    setLoading(true);
    fetch(`${BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
          setLoading(false);
          return;
        } else if (data.token) {
          localStorage.setItem("radio-token", data.token);
          window.location.href = "/";
        }
      });
  };



  return (
    <div className="md:relative font-DM_Sans">
      <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <div className="lg:container lg:mx-auto">
        <div className="px-2 md:pt-[120px] flex flex-wrap md:flex-nowrap justify-around items-center">
          <div className="flex-0 md:flex-1">
            <img src={login_image} alt="login_image" className="w-[250px] h-[250px] md:w-[500px] md:h-[500px]" />
          </div>
          <div className="flex-1 px-5">
            <form className="flex justify-center md:w-10/12" onSubmit={handleSubmit}>
              <div class="flex flex-col items-center w-full py-2 px-3 rounded-2xl mb-4 ">
                <div className="hidden md:flex flex-col py-4 justify-start w-full">
                  <p className="font-Poppins font-medium text-xl md:text-5xl">Welcome to</p>
                  <p className="font-DM_Sans font-semibold text-[32px] md:text-[60px] text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">Radio Go</p>
                </div>
                <SocialLogin/>
                <div className="pb-4">OR</div>
                <div class="flex items-center border-2 w-full py-2 px-3 gap-x-4 rounded-md mb-4 bg-[#ECECEC]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                  </svg>
                  <div className="flex flex-col w-full">
                    <p className="font-normal text-xs">Email</p>
                    <input
                      required
                      class="outline-none border-none bg-[#ECECEC] w-full"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@gmail.com"
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      value={formData.email}
                    />
                  </div>
                </div>
                <div class="flex items-center border-2 w-full py-2 px-3 gap-x-4 rounded-md mb-4 bg-[#ECECEC]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd" />
                  </svg>
                  <div className="flex w-full items-center">
                    <div className="flex flex-1 flex-col">
                      <p className="font-normal text-xs">Password</p>
                      <input
                        required
                        className="flex-1 outline-none border-none bg-[#ECECEC] w-full"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="*******"
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        value={formData.password}
                      />
                    </div>
                    {showPassword ?
                      <svg onClick={() => setShowPassword(false)} class="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                      </svg> :
                      <svg onClick={() => setShowPassword(true)} class="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path>
                      </svg>
                    }
                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between w-full text-sm">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <input type="checkbox" className="checkbox" value={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} checked={rememberMe} />
                      <span className="pl-2 label-text">Remember me</span>
                    </label>
                  </div>
                  <Link className="hover:text-blue-500 decoration-1" to={"/forget-password"}>Forgot Password?</Link>
                </div>
                <div className="py-4 w-full">
                  {
                    loading ? (
                      <button
                        className="mb-2 bg-emerald-500 w-full hover:bg-emerald-700 text-white font-bold py-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit">
                        Please wait...
                      </button>
                    ) : (
                      <button
                        className="mb-2 w-full flex items-center justify-center gap-x-2 text-white text-center bg-gradient-to-r from-red-700 to-purple-800 font-bold py-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleSubmit} type="button">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
</svg>

                        Login
                      </button>
                    )
                  }
                  <span className="flex justify-center pt-4">Don't have an account? <Link className="hover:text-blue-500 underline decoration-1 ml-2" to={"/registration"}>Register</Link></span>
                </div>

              </div>
            </form>
          </div>
        </div>
        <FooterNev />
      </div>
    </div>
  );
}

export default Login;

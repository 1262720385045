import React from 'react'
import brandlogo from '../assets/image/brandlogo.png'
import legal from '../assets/image/legal.png'
import shapeleft from '../assets/image/shapeleft.svg'
import shaperight from '../assets/image/shaperight.svg'
import FooterNev from './FooterNev'
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function BrandGuidline() {
    // scroll to top on page load
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className="md:relative">
            <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <div className="lg:container lg:mx-auto">
                <div className='py-6 px-2 lg:px-0'>
                    <div className='flex py-2 items-start'>
                        <div className='flex-1 space-y-2'>
                            <p className='font-medium w-6/6 text-left text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-relaxed tracking-normal'>Creator, Sell or Collect Digital item your <b className='font-semibold text-3xl lg:text-5xl xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-purple-500'>NFTs</b></p>
                            <p className='font-normal w-5/6 text-justify text-sm lg:text-lg leading-1 pt-3'>
                                Many desktop publishing packages and web page editors now
                                Ipsum as their default model text, and a search for 'lorem
                                uncover many web sites still in their infancy.
                            </p>
                            <div className='w-6/6 lg:w-4/6 flex justify-center space-x-3 py-10'>
                                <button className='py-2 px-4 text-sm lg:text-base bg-pink-500 text-white rounded-full'>Create Now</button>
                                <button className='py-2 px-4 text-sm lg:text-base bg-indigo-500 text-white rounded-full'>Explore Now</button>
                            </div>
                        </div>
                        <div className='md:flex-1 hidden md:flex justify-center'>
                            <img src={legal} alt="legal" className='h-44' />
                        </div>
                    </div>
                    <div className='flex py-10 items-center justify-between  flex-wrap gap-7 lg:gap-0 mb-16'>
                        <div className='flex justify-center items-center h-[80px] w-[80px] lg:h-[170px] lg:w-[170px] bg-gradient-to-r from-red-700 to-purple-800 rounded-md'>
                            <div className='flex justify-center items-center m-2 bg-white hover:bg-pink-500 hover:text-white rounded-md'>
                                <div className='flex flex-col justify-around items-center h-[76px] w-[76px] lg:h-[166px] lg:w-[166px]'>
                                    <img src={brandlogo} alt="brandlogo" className='h-[30px] lg:h-[60px]' />
                                    <p className='font-semibold text-xs md:text-sm'>Brand</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center h-[80px] w-[80px] lg:h-[170px] lg:w-[170px] bg-gradient-to-r from-red-700 to-purple-800 rounded-md'>
                            <div className='flex justify-center items-center m-2 bg-white hover:bg-pink-500 hover:text-white rounded-md'>
                                <div className='flex flex-col justify-around items-center h-[76px] w-[76px] lg:h-[166px] lg:w-[166px]'>
                                    <img src={brandlogo} alt="brandlogo" className='h-[30px] lg:h-[60px]' />
                                    <p className='font-semibold text-xs md:text-sm'>Brand</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center h-[80px] w-[80px] lg:h-[170px] lg:w-[170px] bg-gradient-to-r from-red-700 to-purple-800 rounded-md'>
                            <div className='flex justify-center items-center m-2 bg-white hover:bg-pink-500 hover:text-white rounded-md'>
                                <div className='flex flex-col justify-around items-center h-[76px] w-[76px] lg:h-[166px] lg:w-[166px]'>
                                    <img src={brandlogo} alt="brandlogo" className='h-[30px] lg:h-[60px]' />
                                    <p className='font-semibold text-xs md:text-sm'>Brand</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center h-[80px] w-[80px] lg:h-[170px] lg:w-[170px] bg-gradient-to-r from-red-700 to-purple-800 rounded-md'>
                            <div className='flex justify-center items-center m-2 bg-white hover:bg-pink-500 hover:text-white rounded-md'>
                                <div className='flex flex-col justify-around items-center h-[76px] w-[76px] lg:h-[166px] lg:w-[166px]'>
                                    <img src={brandlogo} alt="brandlogo" className='h-[30px] lg:h-[60px]' />
                                    <p className='font-semibold text-xs md:text-sm'>Brand</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center h-[80px] w-[80px] lg:h-[170px] lg:w-[170px] bg-gradient-to-r from-red-700 to-purple-800 rounded-md'>
                            <div className='flex justify-center items-center m-2 bg-white hover:bg-pink-500 hover:text-white rounded-md'>
                                <div className='flex flex-col justify-around items-center h-[76px] w-[76px] lg:h-[166px] lg:w-[166px]'>
                                    <img src={brandlogo} alt="brandlogo" className='h-[30px] lg:h-[60px]' />
                                    <p className='font-semibold text-xs md:text-sm'>Brand</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center h-[80px] w-[80px] lg:h-[170px] lg:w-[170px] bg-gradient-to-r from-red-700 to-purple-800 rounded-md'>
                            <div className='flex justify-center items-center m-2 bg-white hover:bg-pink-500 hover:text-white rounded-md'>
                                <div className='flex flex-col justify-around items-center h-[76px] w-[76px] lg:h-[166px] lg:w-[166px]'>
                                    <img src={brandlogo} alt="brandlogo" className='h-[30px] lg:h-[60px]' />
                                    <p className='font-semibold text-xs md:text-sm'>Brand</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='flex justify-center items-center bg-gradient-to-r from-red-700 to-purple-800 rounded-md w-full h-screen'>
                    <div className='justify-center items-center m-[3px] bg-white rounded-md w-[99vw] h-[99vh]'>
                    </div>
                </div> */}
                    <div className='border-2 border-red-400 rounded-md w-full h-screen'>

                    </div>
                    <div className="flex justify-center py-5">
                        <div className="adh w-2/3 h-24 md:block hidden self-center mt-[50px]"  style={{display:"flex", justifyContent:'center'}}>
                            <AdsHorizontalComponent/>
                            {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
                        </div>
                    </div>

                </div>
                <FooterNev />
            </div>
        </div>
    )
}

export default BrandGuidline
import React, { createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './components/About';
import Account from './components/Account';
import AddStation from './components/AddStation';
import { AuthLayout } from './components/admin/Auth';
import Dashboard from './components/admin/Dashboard';
import Item from './components/admin/Item';
import BrandGuidline from './components/BrandGuidline';
import Category from './components/Category';
import Contact from './components/Contact';
import ContactUs from './components/ContactUs';
import Landing from './components/Landing';
import { LandingLayout } from './components/layoutLanding';
import Legal from './components/Legal';
import Login from './components/Login';
import Player from './components/Player';
import Region from './components/Region';
import Registration from './components/Registration';
import Stations from './components/Stations';
import { BASE_URL } from './components/BaseURL';
import ForgetPassword from './components/ForgetPassword';

export const UserContext = createContext();

function App() {
  const [userData, setUserData] = React.useState(null)

  React.useEffect(() => {
    const token = localStorage.getItem('radio-token');
    if (token) {
      const url = `${BASE_URL}/get-user-info`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      }
      fetch(url, options)
        .then(res => res.json())
        .then(data => {
          if (data.id) setUserData(data);
        })
    }
  }, [])


  return (
    <>
    <UserContext.Provider value={{ userData, setUserData }}>
      <Routes>
        <Route path="/" element={<LandingLayout />} >
          <Route index element={<Landing />} />
          <Route path="account" element={<Account />} />
          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />
          <Route path="about" element={<About />} />
          <Route path="regions" element={<Region />} />
          <Route path="categories" element={<Category />} />
          <Route path="player/:id" element={<Player />} />
          <Route path="contact" element={<Contact />} />
          <Route path="legal" element={<Legal />} />
          <Route path="brand-guidline" element={<BrandGuidline />} />
          <Route path="add-station" element={<AddStation />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="stations/:param" element={<Stations />} />
          <Route path=":id" element={<Player />} />
          <Route path="forget-password" element={<ForgetPassword />} />
        </Route>
        <Route path="/home" element={<AuthLayout />} >
          <Route index element={<Dashboard />} />
          <Route path='items' element={<Item />} />
        </Route>
      </Routes>
    </UserContext.Provider>
    </>
  );
}

export default App;
import React from "react";
import { Link } from "react-router-dom";
import info from "../assets/image/info.svg";
import lineShape from "../assets/image/line.png";
import play from "../assets/image/play.svg";
import user from "../assets/image/user.svg";
import edit from "../assets/image/edit.svg";
import love from "../assets/image/love.svg";
import shapeleft from "../assets/image/shapeleft.svg";
import shaperight from "../assets/image/shaperight.svg";
import station_1 from "../assets/image/station-1.png";
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";

import fb from "../assets/image/fb.svg";
import google from "../assets/image/google.svg";

function EditProfile() {
  const [data, setData] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [allRegionsAndCategories, setAllRegionsAndCategories] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  React.useEffect(() => {
    fetch(`${BASE_URL}/allcategories`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.categories);
        //setStations(data.stations);
      });
  }, []);

  const handleSubmit = () => {

    if (formData.name === "") {
      alert("Please Enter Station Name");
      return;
    }
    if (formData.email === "") {
      alert("Please Enter Email");
      return;
    }
    if (formData.message === "") {
      alert("Please Enter Message");
      return;
    }
  };

  return (
    <div className="flex flex-col xs:col-span-12 col-span-1 lg:col-span-10 md:col-span-10">
      <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1rem]">
        <form className="w-full flex flex-col items-center md:items-start">
          <div className="flex flex-col sm:flex-row my-2 w-8/12">
              <label className="w-full md:w-6/12">First Name</label>
              <input className="w-full p-2 bg-gray-100 border-[1px] rounded-md" type="text" />
          </div>
          <div className="flex flex-col sm:flex-row my-2 w-8/12">
              <label className="w-full md:w-6/12">Surname</label>
              <input className="w-full p-2 bg-gray-100 border-[1px] rounded-md" type="text" />
          </div>
          <div className="flex flex-col sm:flex-row my-2 w-8/12">
              <label className="w-full md:w-6/12">Password</label>
              <input className="w-full p-2 bg-gray-100 border-[1px] rounded-md" type="password" />
          </div>
          <div className="flex flex-col sm:flex-row my-2 w-8/12">
              <label className="w-full md:w-6/12">Confirm Password</label>
              <input className="w-full p-2 bg-gray-100 border-[1px] rounded-md" type="password" />
          </div>
          <div>
            <button className="bg-gray-500 text-white px-6 py-1 rounded-full mt-4" type="button">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;

import React, {useEffect} from 'react';

const AdsComponent = (props) => {
    const {dataAdSlot} = props;
    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            //(adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.log(e)
        }

    }, []);


    return (
        <div style={{width: 300, height: 250}}>
            <ins className="adsbygoogle"
                 style={{display:"block", height: "336px", width: "336px"}}
                 data-ad-client="ca-pub-7288998721146185"
                 data-ad-slot="8292285304"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </div>

    );
};

export default AdsComponent;
import React from 'react'

export default function Audio({src, play, audioRef}) {

    React.useEffect(() => {
        if (play) {
            audioRef.current.play()
        } else {
            audioRef.current.pause()
        }
    }, [play, audioRef])

    React.useEffect(() => {
        audioRef.current.volume = 0.5
    }, [audioRef])

    // check if scr change, if yes, pause the audio and play it again
    React.useEffect(() => {
        audioRef.current.pause()
        audioRef.current.load()
        audioRef.current.play()
    }, [src, audioRef])

  return (
    <div style={{ display: 'none' }}>
        <audio id="myAudio" controls autoPlay={true} ref={audioRef}>
            <source src={src} type="audio/mpeg" onError={(e) => alert("Can't play this Radio. Please try again later.")} />
            Your browser does not support the audio element.
        </audio>
    </div>
  )
}

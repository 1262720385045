import React from "react";
import { Link } from "react-router-dom";
import info from "../assets/image/info.svg";
import lineShape from "../assets/image/line.png";
import play from "../assets/image/play.svg";
import shapeleft from "../assets/image/shapeleft.svg";
import shaperight from "../assets/image/shaperight.svg";
import station_1 from "../assets/image/station-1.png";
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";
import SingleRadio from "./radioContainer/SingleRadio";
import AdsComponent from "./radioContainer/AdsComponent";
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function Category() {
  const [data, setData] = React.useState([]);
  const [stations, setStations] = React.useState([]);
  const [showTag, setShowTag] = React.useState(false);
  const imageDomain = BASE_URL.replace("gr/api", "gr");

  // scroll to top on page load
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  React.useEffect(() => {
    fetch(`${BASE_URL}/allcategories`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.categories);
        setStations(data.stations);
      });
  }, []);

  return (
    <div className="md:relative">
      <img
        className="invisible sm:visible absolute top-0 right-0 -z-50"
        src={shaperight}
        alt="shapeRight"
      />
      <img
        className="invisible sm:visible absolute top-0 left-0 -z-50"
        src={shapeleft}
        alt="shapeleft"
      />
      <img
        className="invisible sm:visible absolute bottom-0 right-0 -z-50"
        src={shaperight}
        alt="shapeRight"
      />
      <img
        className="invisible sm:visible absolute bottom-0 left-0 -z-50"
        src={shapeleft}
        alt="shapeleft"
      />

      <div className="lg:container lg:mx-auto">
        <div className="flex flex-col items-center space-y-6">
          <p className="text-4xl font-semibold pt-12 pb-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
            Categories
          </p>
          <img src={lineShape} alt="line" />
          <p className="text-center text-gray-500 md:block hidden font-DM_Sans italic" style={{maxWidth:'500px'}}>
          Get lost in a world of show categories ranging from chart-topping tunes to breaking news, and uncover your next radio obsession.
          </p>
          <div className="flex flex-wrap w-4/4 md:w-2/4 justify-center gap-2 pt-6">
          <Link to={`/stations/Internet`}>
                <div
                  className="text-xs border-2 border-slate-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-purple-500 text-white rounded-full px-4 py-1 font-Sans_Serif font-medium"
                >
                  Internet Radio
                </div>
              </Link>
              <Link to={`/stations/Frequency`}>
                <div
                  className="text-xs border-2 border-slate-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-purple-500 text-white rounded-full px-4 py-1 font-Sans_Serif font-medium"
                >
                  Frequency
                </div>
              </Link>
          {data?.length >= 12 && data?.slice(0,12)?.map((item, index) => (
              <Link key={index} to={`/stations/${item.name}`}>
                <div
                  className="text-xs border-2 border-slate-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-purple-500 text-white rounded-full px-4 py-1 font-Sans_Serif font-medium"
                >
                  {item.name}
                </div>
              </Link>
            ))}
            {(showTag === false && data?.length > 13) && 
              <div className="w-full text-center bg-white opacity-90 -mt-6 pt-5" onClick={() => setShowTag(true)}>
                <div className="flex flex-col items-center">
                  <button className="flex flex-col items-center justify-center">
                  <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">See More</p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h- text-purple-500">
                    <path fillRule="evenodd" d="M2.85852 4.00543L0.140895 1.27543C0.0508084 1.18523 0.000203563 1.06294 0.000203569 0.935428C0.000203574 0.807917 0.0508084 0.685624 0.140895 0.595429L0.592666 0.141429C0.636998 0.0966205 0.68977 0.0610501 0.747928 0.0367745C0.806087 0.0124989 0.868478 -2.41637e-07 0.931495 -2.38882e-07C0.994511 -2.36128e-07 1.0569 0.0124989 1.11506 0.0367745C1.17322 0.0610501 1.22599 0.0966205 1.27032 0.141429L3.19635 2.07743L5.12237 0.142429C5.1667 0.0976206 5.21947 0.0620504 5.27763 0.0377748C5.33579 0.0134992 5.39818 0.000999884 5.4612 0.000999887C5.52421 0.000999889 5.5866 0.0134992 5.64476 0.0377748C5.70292 0.0620504 5.75569 0.0976206 5.80002 0.142429L6.25579 0.594429C6.34588 0.684624 6.39648 0.806918 6.39648 0.934429C6.39648 1.06194 6.34588 1.18423 6.25579 1.27443L3.53817 4.00443C3.49387 4.04961 3.44103 4.08551 3.38272 4.11005C3.32441 4.13459 3.26181 4.14728 3.19855 4.14737C3.1353 4.14746 3.07265 4.13496 3.01428 4.11059C2.9559 4.08622 2.90295 4.05047 2.85852 4.00543ZM3.53717 7.86043L6.25579 5.12943C6.34588 5.03923 6.39648 4.91694 6.39648 4.78943C6.39648 4.66192 6.34588 4.53962 6.25579 4.44943L5.80402 3.99543C5.75969 3.95062 5.70692 3.91505 5.64876 3.89077C5.5906 3.8665 5.52821 3.854 5.46519 3.854C5.40218 3.854 5.33979 3.8665 5.28163 3.89077C5.22347 3.91505 5.1707 3.95062 5.12637 3.99543L3.20034 5.93043L1.27332 3.99543C1.22899 3.95062 1.17622 3.91505 1.11806 3.89077C1.0599 3.8665 0.997509 3.854 0.934492 3.854C0.871476 3.854 0.809085 3.8665 0.750927 3.89077C0.692768 3.91505 0.639997 3.95062 0.595664 3.99543L0.145893 4.44943C0.055806 4.53962 0.00520066 4.66192 0.00520065 4.78943C0.00520065 4.91694 0.0558059 5.03923 0.145893 5.12943L2.86251 7.86043C2.90661 7.9053 2.95919 7.94093 3.01719 7.96526C3.07519 7.98959 3.13745 8.00212 3.20034 8.00212C3.26323 8.00212 3.3255 7.98959 3.3835 7.96526C3.4415 7.94093 3.49408 7.9053 3.53817 7.86043L3.53717 7.86043Z" clipRule="evenodd" />
                  </svg>
                  </button>
                </div>
              </div>
            }
            {showTag && data?.length >= 13 && data?.slice(13)?.map((item, index) => (
              <Link key={index} to={`/stations/${item.name}`}>
                <div
                  className="text-xs border-2 border-slate-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-purple-500 text-white rounded-full px-4 py-1 font-Sans_Serif font-medium"
                >
                  {item.name}
                </div>
              </Link>
            ))}
            
          </div>
          {/* <div className="bg-gray-300 w-2/3 h-24 md:block hidden self-center">
              <div className=''></div>
              <p className='text-[10px] font-light text-right pt-[98px] text-gray-300'>ADVERTISEMENT</p>
          </div> */}
        </div>
        <div className="flex flex-col space-y-6 pt-12">
          {/* <p className="font-normal font-Sans_Serif text-lg md:text-xl text-center md:text-left self-center md:self-start w-[220px] md:w-full border-b border-y-rose-900 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
            ΕΛΛΗΝΙΚΕΣ ΕΠΙΤΥΧΙΕΣ
          </p> */}
          <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-5">
            <div className="flex flex-col xs:col-span-12 sm:col-span-1 lg:col-span-12 md:col-span-12">
              <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1rem]">
                {stations?.length >=30 && stations.slice(0,30).map((item, index) => (
                  <Link to={`/${item?.secondary_domain?.length? item.secondary_domain: 'player/' + item.code_number}`} key={index} className={index < 5 ? "visible" : "hidden md:block"}>
                    <div
                      className="group flex items-center w-[200px] h-[50px] justify-between hover:bg-gradient-to-r from-[#AF0909] to-[#da5151] border border-gray-300 text-black hover:text-white  rounded-full p-[1px]"
                    >
                      <img
                        className="w-[46.2px] h-[46.2px] m-[1px] rounded-full border-0.5"
                        src={item.logo.includes("http://127.0.0.1:8000") ? item.logo.replace("http://127.0.0.1:8000", imageDomain) : item.logo}
                        alt="staion-1"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = station_1;
                        }}
                      />
                      <div className="flex flex-col items-start font-400 text-xs">
                        <p className="font-bold text-xs text-[#757575] group-hover:text-white">
                          {item?.name.length > 12
                            ? item?.name.slice(0, 12) + "..."
                            : item?.name}
                        </p>
                        <div className="flex flex-row justify-between text-[9px] mb-[2px] -mt-[4px]">
                          <div className="text-[9px] ">
                            <p
                              className="pb-[2px] text-red-600 font-medium group-hover:text-[#C8C8C8]"
                              style={{
                                overflowX: "hidden",
                                whiteSpace: "nowrap",
                                width: "80px",
                              }}
                            >
                              {item?.tags.length > 15
                                ? item?.tags.slice(0, 15) + "..."
                                : item?.tags}
                            </p>
                            <div
                              className="border-0.5 py-[5px] w-[60.62px] font-Poppins h-[9.77px] text-[#757575] group-hover:text-white text-[9px] border-slate-200 text-center flex items-center justify-center bg-gray-100 group-hover:bg-red-800 rounded-full"

                            >
                              <p className="text-[9px]">
                                {item.city_region?.name.length > 10
                                  ? item.city_region["name"].slice(0, 10) + "..."
                                  : item.city_region["name"]}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col items-center justify-between">
                            <img className="w-3 h-3" src={info} alt="info" />
                            <p className="font-medium text-[#757575] group-hover:text-white">{item.frequency ? "FM" : "Web"}</p>
                          </div>
                        </div>
                      </div>
                      <img className="w-8 h-8 mr-1" src={play} alt="staion-1" />
                    </div>
                  </Link>
                ))}
                {stations?.length >=50 && 
                  <div className={`grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-4`}>
                  <div className={`flex ${true ? "order-first" : "order-first md:order-last" } flex-col xs:col-span-12 lg:col-span-8 md:col-span-12 gap-y-6`}>
                    <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1.1rem] gap-y-5">
                    {stations.slice(30,50).map((station, index) => {
                            if (index < 20){
                                return (
                                    <SingleRadio index={index} station={station} />
                                )
                            } else return null
                        })}
                    </div>
                    </div>
                    <div className="justify-self-center xs:block md:block lg:block xs:col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 w-[300px] md:w-[300px] lg:w-[418px] p-2 md:px-[59px] md:py-[41.5px] lg:border-0.5 h-[333px]">
                    <div className="ads h-full md:h-[250px] md:w-[300px]" style={{display:"flex", justifyContent:'center'}}>
                        <AdsComponent/>
                    </div>
                    {/* <p style={{fontSize:'10px'}} className="font-light text-center pt-2 text-gray-300">
                        ADVERTISEMENT
                    </p> */}
                    </div>
                  </div>
                }
                {stations?.length >=72 &&
                  <div className={`grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-4`}>
                      <div className={`flex ${true ? "order-first" : "order-first md:order-last" } flex-col xs:col-span-12 lg:col-span-12 md:col-span-12 gap-y-6`}>
                      <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1.1rem] gap-y-5">
                          {stations.slice(50,68).map((station, index) => {
                              if (index < 68){
                                  return (
                                      <SingleRadio index={index} station={station} />
                                  )
                              } else return null
                          })}
                          <div className='flex w-full only-show-lg'>
                              <div className="w-1/6 ">
                                  <div className='mb-5'>
                                      {stations[68] && (
                                          <SingleRadio index={18} station={stations[68]} />
                                      )}
                                  </div>
                                  <div>
                                      {stations[69] && (
                                          <SingleRadio index={19} station={stations[69]} />
                                      )}
                                  </div>
                              </div>
                              <div className="w-4/6 flex justify-end flex-col items-center">
                                  <div className="adh h-[90px] w-[728px]" style={{display:"flex", justifyContent:'center'}}>
                                      <AdsHorizontalComponent/>
                                  </div>
                                  {/* <div style={{fontSize:'10px'}} className='w-[728px] font-light text-center pt-2 text-gray-300'>ADVERTISEMENT</div> */}
                              </div>
                              <div className="w-1/6">
                                  <div className='mb-5'>
                                      {stations[70] && (
                                          <SingleRadio index={20} station={stations[70]} />
                                      )}
                                  </div>
                                  <div>
                                      {stations[71] && (
                                          <SingleRadio index={21} station={stations[71]} />
                                      )}
                                  </div>
                              </div>
                          </div>
                      </div>
                      </div>
                      
                  </div>
                }
                {stations?.length >=92 && 
                  <div className={`grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-4`}>
                  <div className={`flex ${true ? "order-first" : "order-first md:order-last" } flex-col xs:col-span-12 lg:col-span-8 md:col-span-12 gap-y-6`}>
                    <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1.1rem] gap-y-5">
                    {stations.slice(72,92).map((station, index) => {
                            if (index < 20){
                                return (
                                    <SingleRadio index={index} station={station} />
                                )
                            } else return null
                        })}
                    </div>
                    </div>
                    <div className="justify-self-center xs:block md:block lg:block xs:col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 w-[300px] md:w-[300px] lg:w-[418px] p-2 md:px-[59px] md:py-[41.5px] lg:border-0.5 h-[333px]">
                    <div className="ads h-full md:h-[250px] md:w-[300px]" style={{display:"flex", justifyContent:'center'}}>
                        <AdsComponent/>
                    </div>
                    {/* <p style={{fontSize:'10px'}} className="font-light text-center pt-2 text-gray-300">
                        ADVERTISEMENT
                    </p> */}
                    </div>
                  </div>
                }
              </div>
              <div className="flex justify-center md:justify-end text-red-500 hover:cursor-pointer">
                <Link to={`/stations/all`} className="flex items-center gap-x-2" style={{zIndex:'100'}}>
                  <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">See More</p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-purple-500">
                    <path fillRule="evenodd" d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                    <path fillRule="evenodd" d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                  </svg>

                </Link>
              </div>
            </div>
          </div>
          <div className="adh w-2/3 h-24 md:block hidden self-center" style={{display:"flex", justifyContent:'center'}}>
              <AdsHorizontalComponent/>
              {/* <p className='text-[10px] font-light text-right pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
          </div>
        </div>
        <FooterNev />
      </div>
    </div>
  );
}

export default Category;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import lineShape from '../assets/image/line.png';
import station_1 from '../assets/image/station-1.png';
import { BASE_URL } from './BaseURL';
import AdsHorizontalComponent from './radioContainer/AdsHorizontalComponent';

function Header({ randomStation }) {
    const imageDomain = BASE_URL.replace("gr/api", "gr");
    const navigate = useNavigate();
    const [input, setInput] = React.useState('');
    const searchHandler = (e) => {
        e.preventDefault();
        navigate(`/stations/${input}`);
    };
    return (
        <div className='flex flex-col items-center space-y-6 pt-12'>
            <div className="flex md:hidden items-center gap-2">
                <div className='flex border bottom-1 w-[185px] rounded-full  bg-gradient-to-r from-red-700 to-purple-800'>
                    <div className='flex justify-center items-center m-[2px] bg-white hover:text-white rounded-full'>
                        <div className='flex justify-center items-center w-[180px]'>
                            <input type="text" className='rounded-full text-black w-4/5 px-2 py-1 my-1 text-sm focus-visible:outline-none' placeholder='Search Here' value={input} onChange={(e) => setInput(e.target.value)} />
                            <svg onClick={searchHandler} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-4xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">You Love Them!</p>
            <img className='md:block hidden' src={lineShape} alt="line" />
            <p className="text-center text-gray-500 md:block hidden font-DM_Sans italic">Discover your favorite shows and stations handpicked just for you on this personalized page.</p>
            <div className="grid grid-cols-3 gap-2 md:flex md:justify-center md:space-x-3">
                {randomStation.map((station, index) => (
                    <Link to={`/${station?.secondary_domain?.length ? station.secondary_domain : 'player/' + station.code_number}`} key={index}>
                        <img key={index} src={station.logo.includes("http://127.0.0.1:8000") ? station.logo.replace("http://127.0.0.1:8000", imageDomain) : station.logo} alt="staion-1" style={{ minWidth: '65px', minHeight: '65px', maxWidth: '65px', maxHeight: '65px', borderRadius: '50px', border: '2px solid gray' }} onError={(e) => { e.target.onerror = null; e.target.src = station_1 }} />
                    </Link>
                ))}
            </div>
            <div className="adh w-2/3 h-24" style={{display:"flex", justifyContent:'center'}}>
                    <AdsHorizontalComponent />
                {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
            </div>
        </div>
    )
}

export default Header
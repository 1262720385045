import React, {useEffect} from 'react';

const AdsHorizontalComponent = (props) => {
    const {dataAdSlot} = props;
    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            //(adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.log(e)
        }

    }, []);


    return (
        <>
            <ins className="adsbygoogle"
                 style={{display:"block", height: "90px", width: "500px"}}
                 data-ad-client="ca-pub-7288998721146185"
                 data-ad-slot="8200186891"
                 data-ad-format="auto"
                 data-full-width-responsive="false"></ins>
        </>

    );
};

export default AdsHorizontalComponent;
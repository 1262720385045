import React from 'react'

function Footer() {
    return (
        <div className='py-2 bg-gradient-to-r from-red-700 to-purple-800'>
            <p className='text-center text-white text-xs'>©️ 2023 RadioGo. All rights reserved.</p>
        </div>
    )
}

export default Footer
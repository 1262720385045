import React from 'react';
import { Link } from "react-router-dom";
import info from "../../assets/image/info.svg";
import play from "../../assets/image/play.svg";
import station_1 from "../../assets/image/station-1.png";
import { BASE_URL } from '../BaseURL';

export default function NoAd({ index2, item, data }) {
  const imageDomain = BASE_URL.replace("gr/api", "gr");
  
  return (
    <div key={index2} className="flex flex-col space-y-6 pt-12 font-Sans_Serif">
      <p className="font-normal font-Sans_Serif text-lg md:text-xl text-center md:text-left self-center md:self-start w-[220px] md:w-full border-b border-y-rose-900 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
        {item}
      </p>
      <div className={`grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-4`}>
        <div className={`flex ${index2 % 2 === 0 ? "order-first" : "order-first md:order-last"} flex-col xs:col-span-12 lg:col-span-12 md:col-span-12 gap-y-6`}>
          <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1.1rem] gap-y-5">
            {data[item].map((station, index) => (
              <Link target={'_blank'} to={`/player/${station?.secondary_domain?.length ? station.secondary_domain : station.code_number}`} key={index} className={index < 5 ? "visible" : "hidden md:block"}>
                <div
                  key={index}
                  className="group flex items-center w-[200px] h-[50px] justify-between hover:bg-gradient-to-r from-[#AF0909] to-[#da5151] border border-gray-300 text-black hover:text-white  rounded-full p-[1px]"
                >
                  <img
                    className="w-[46.2px] h-[46.2px] m-[1px] rounded-full border-0.5"
                    src={station.logo.includes("http://127.0.0.1:8000") ? station.logo.replace("http://127.0.0.1:8000", imageDomain) : station.logo}
                    alt="staion-1"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = station_1;
                    }}
                  />
                  <div className="flex flex-col items-start font-400 text-xs">
                    <p className="font-bold text-xs text-[#757575] group-hover:text-white">
                      {station?.name.length > 12
                        ? station?.name.slice(0, 12) + "..."
                        : station?.name}
                    </p>
                    <div className="flex flex-row justify-between text-[9px] mb-[2px] -mt-[4px]">
                      <div className="text-[9px] ">
                        <p
                          className="pb-[2px] text-red-600 font-medium group-hover:text-[#C8C8C8]"
                          style={{
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                            width: "80px",
                          }}
                        >
                          {station?.tags.length > 15
                            ? station?.tags.slice(0, 15) + "..."
                            : station?.tags}
                        </p>
                        <div
                          className="border-0.5 py-[5px] w-[60.62px] h-[9.77px] text-[#757575] group-hover:text-white text-[9px] border-slate-200 text-center flex items-center justify-center bg-gray-100 group-hover:bg-red-800 rounded-full"

                        >
                          <p className="text-[9px]">
                            {station?.city_region?.name?.length > 10
                              ? station.city_region["name"].slice(0, 10) + "..."
                              : station?.city_region?.name ? station.city_region["name"] : "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-between">
                        <img className="w-3 h-3" src={info} alt="info" />
                        <p className="font-medium text-[#757575] group-hover:text-white">{station.frequency ? "FM" : "Web"}</p>
                      </div>
                    </div>
                  </div>
                  <img className="w-8 h-8 mr-1" src={play} alt="staion-1" />
                </div>
              </Link>
            ))}
          </div>
          <div className="flex justify-center md:justify-end text-red-500 hover:cursor-pointer">
            <Link to={`/stations/${item}`} className="flex items-center gap-x-2">
              <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">See More</p>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 text-purple-500">
                <path fillRule="evenodd" d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}

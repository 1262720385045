import React from 'react'
import shapeleft from '../assets/image/shapeleft.svg'
import shaperight from '../assets/image/shaperight.svg'
import { BASE_URL } from './BaseURL'
import Content from './Content'
import Cookie from './Cookie.'
import FooterNev from './FooterNev'
import Header from './Header'
import SocialMedia from './SocialMedia'
import Tags from './Tags'

function Landing() {
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)

  // scroll to top on page load
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    React.useEffect(() => {
        fetch(`${BASE_URL}/`)
            .then(res => res.json())
            .then(data => {
                setData(data)
                setLoading(false)
            })
    }, [])
    return (
        <div className="md:relative">
            <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <img className="invisible sm:visible absolute top-[2972px] right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute top-[2972px] left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <div className="lg:container lg:mx-auto">
                <Header randomStation={data.random9 ? data.random9 : []} />
                <Content station={data.station20 ? data.station20 : []}
                    data={data.data ? data.data : {}} />
                <SocialMedia />
                <Tags tags={data?.categoriesAndRegions ? data?.categoriesAndRegions : []} />
                <FooterNev />
                <Cookie />
            </div>
        </div>
    )
}

export default Landing
import { Navigate, Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ScrollButton from "./ScrollButton";

export const LandingLayout = () => {
    //const { user } = useAuth();
    const isLogedIn = true;

    if (!isLogedIn) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Navbar />
            <Outlet />
            <ScrollButton />
            <Footer />
        </>
    )
};
import React from 'react'
import { Link } from 'react-router-dom'
import lineShape from '../assets/image/line.png'
import AdsComponent from "./radioContainer/AdsComponent";
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function Tags({ tags }) {
    return (
        <div className='flex flex-col items-center space-y-6 pt-12'>
            <p className="text-4xl py-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">Categories</p>
            <img className='md:block hidden' src={lineShape} alt="line" />
            <p className="text-center md:block hidden font-DM_Sans italic text-[#757575] font-normal text-[14px]">Get lost in a world of show categories ranging from <br/>chart-topping tunes to breaking news, and uncover your next radio obsession.</p>
            <div className="flex flex-wrap w-4/4 md:w-2/4 justify-center gap-2 pt-6 font-DM_Sans">
                {tags.map((item, index) => {
                    if (item.type_name === 'Category') {
                        return (
                            <Link to={`/stations/${item.name}`} >
                                <div key={index} className='text-xs hover:bg-gradient-to-r from-red-700 to-purple-800 bg-[#8364E2] text-white rounded-full border-none px-[14px] py-[6px] font-Sans_Serif font-medium'>
                                    {item.name}
                                </div>
                            </Link>
                        )
                    } else return null
                })}
            </div>

            <div className="py-10">
                <div className="justify-self-center block md:hidden xs:col-span-12 sm:col-span-12 w-[300px] md:w-[350px]  p-2 h-[250px]">
                    <div className="ads h-full"><AdsComponent/></div>
                    {/* <p className="font-light text-xs text-center pt-2 text-gray-300">
                        ADVERTISEMENT
                    </p> */}
                </div>
            </div>


            <p className="text-4xl md:block hidden py-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">Regions</p>
            <img className='md:block hidden' src={lineShape} alt="line" />
            <p className="text-center md:block hidden font-DM_Sans italic text-[#757575] font-normal text-[14px]">Enjoy a musical trip around Greece and beyond, <br/>with a diverse range of regional stations to transport you to new places and cultures.</p>
            <div className="md:flex flex-wrap hidden w-4/4 md:w-4/4 justify-center gap-2 pt-6 font-DM_Sans">
                {tags.map((item, index) => {
                    if (item.type_name === 'Region') {
                        return (
                            <Link to={`/stations/${item.name}`} >
                                <div key={index} className='text-xs hover:bg-gradient-to-r from-red-700 to-purple-800 bg-[#8364E2] text-white rounded-full px-4 py-1 font-Sans_Serif font-medium'>
                                    {item.name}
                                </div>
                            </Link>
                        )
                    } else return null
                })}
            </div>
            <div className="adh w-2/3 h-24 md:block hidden" style={{display:"flex", justifyContent:'center'}}>
                <AdsHorizontalComponent/>
                {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
            </div>
            {/* <div className="justify-self-center block md:hidden xs:col-span-12 sm:col-span-12 w-[300px] md:w-[350px]  p-2 h-[250px]">
              <div className="bg-gray-200 h-full"></div>
              <p className="font-light text-xs text-right pt-2 text-gray-300">
                ADVERTISEMENT
              </p>
            </div> */}
        </div>
    )
}

export default Tags
import React from 'react';

function Item() {
    // Loading state
    const [loading, setLoading] = React.useState(false);
    return (
        <div className="lg:container lg:mx-auto">
            <div className="w-3/6 mx-auto">
                <div className="text-center">
                    <h3 className="font-semibold py-2 text-lg">Create Category / Region</h3>
                </div>
                <form onSubmit="">
                    <div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
                        <svg class="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                        </svg>
                        <input
                            required
                            class="pl-2 outline-none border-none w-full"
                            type="text"
                            name="item"
                            id="item"
                            placeholder="Category or Region Name"
                        />
                    </div>

                    <div className="py-4">
                        {
                            loading ? (
                                <button
                                    className="mb-2 bg-emerald-500 px-2 hover:bg-emerald-700 text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline"
                                    type="submit">
                                    Please wait...
                                </button>
                            ) : (
                                <button
                                    className="mb-2 bg-emerald-500 px-2 hover:bg-emerald-700 text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline"
                                    type="submit">
                                    Create Item
                                </button>
                            )
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Item
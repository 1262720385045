import React from 'react';
import {
  LoginSocialFacebook, LoginSocialGoogle
} from 'reactjs-social-login';

import fb from '../assets/image/fb.svg';
import googleIcon from '../assets/image/google.svg';
import { BASE_URL } from './BaseURL';

export default function SocialLogin() {
  const [googleLoginStart, setGoogleLoginStart] = React.useState(false)
  const [facebookLoginStart, setFacebookLoginStart] = React.useState(false)
  const [facebook, setFacebook] = React.useState({
    userId: "",
    accessToken: "",
  });
  const [google, setGoogle] = React.useState({
    accessToken: "",
  });

  React.useEffect(() => {
    if (facebook.userId !== "" && facebook.accessToken !== "") {
      const fburl = `https://graph.facebook.com/${facebook.userId}?fields=name,email&access_token=${facebook.accessToken}`
      fetch(fburl)
        .then(res => res.json())
        .then(data => {
          handleLogin(data)
        })
        .catch(err => console.log(err))
    }
  }, [facebook])

  React.useEffect(() => {
    if (google.accessToken !== "") {
      // get user information including email
      const googleurl = `https://www.googleapis.com/oauth2/v2/userinfo?fields=id,email,name,picture&access_token=${google.accessToken}`
      fetch(googleurl)
        .then(res => res.json())
        .then(data => {
          handleLogin(data)
        })
        .catch(err => console.log(err))
    }
  }, [google])


  const handleLogin = (data) => {
    const url = `${BASE_URL}/social-login`
    const body = {
      email: data.email,
      name: data.name,
      id: data.id,
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(data => {
        if (data.token) {
          localStorage.setItem("radio-token", data.token);
          window.location.href = "/"
        }
        else {
          alert('Something went wrong. Please try again.')
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <div className="w-full">
      <LoginSocialGoogle
        isOnlyGetToken
        client_id='149995469800-kk7fo8cpr9s2b3hpeh0sv0b94ulfrnt1.apps.googleusercontent.com'
        scope='https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
        onLoginStart={() => { setGoogleLoginStart(true) }}
        onResolve={({ provider, data }) => {
          setGoogle({
            accessToken: data.access_token,
          });
        }}
        onReject={(err) => {
        }}
        className="w-full"
      >
        <button class="flex items-center justify-center gap-x-4 border-2 w-full text-center py-4 px-3 rounded-md mb-4 bg-white shadow-md relative">
          <img src={googleIcon} alt="google" className="h-5 w-5" />
          {googleLoginStart ? 'Loading...' : 'Login with Google'}
        </button>
      </LoginSocialGoogle>
      <LoginSocialFacebook
        isOnlyGetToken
        appId='127955000034232'
        onLoginStart={() => { setFacebookLoginStart(true) }}
        onResolve={({ provider, data }) => {
          setFacebook({
            userId: data.userID,
            accessToken: data.accessToken,
          });
        }}
        onReject={(err) => {
          console.log(err)
        }}
        className="w-full"
      >
        <button class="flex items-center justify-center gap-x-2 border-2 w-full text-center py-4 px-3 rounded-md mb-4 bg-white shadow-md relative">
          <img src={fb} alt="fb" className="h-5 w-5" />
          {facebookLoginStart ? 'Loading...' : 'Login with Facebook'}
        </button>
      </LoginSocialFacebook>
    </div>
  )
}

import React from 'react';
import {Link} from "react-router-dom";
import '../../App.css';
import AdsComponent from './AdsComponent';
import SingleRadio from './SingleRadio';
import AdsHorizontalComponent from "./AdsHorizontalComponent";


export default function Container({index2, item, data}) {
    return (
        <div key={index2} className="flex flex-col space-y-6 pt-12 font-Sans_Serif">
            <p className="font-normal text-lg md:text-xl text-center md:text-left self-center md:self-start w-[220px] md:w-full border-b border-y-rose-900 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                {item}
            </p>
            {index2 % 2 === 1 ? (
                <div className={`grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-4`}>
                    <div
                        className={`flex ${index2 % 2 === 1 ? "order-first" : "order-first md:order-last"} flex-col xs:col-span-12 lg:col-span-8 md:col-span-12 gap-y-6`}>
                        <div
                            className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1.1rem] gap-y-5">
                            {data[item].map((station, index) => {
                                if (index < 20) {
                                    return (
                                        <SingleRadio index={index} station={station}/>
                                    )
                                } else return null
                            })}
                        </div>
                        <div className="flex justify-center md:justify-end text-red-500 hover:cursor-pointer">
                            <Link target={'_blank'} to={`/stations/${item}`} className="flex items-center gap-x-2">
                                <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">See
                                    More</p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     className="w-4 h-4 text-purple-500">
                                    <path fillRule="evenodd"
                                          d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                          clipRule="evenodd"/>
                                    <path fillRule="evenodd"
                                          d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                          clipRule="evenodd"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="justify-self-center xs:block md:block lg:block xs:col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 w-[300px] md:w-[300px] lg:w-[418px] p-2 md:px-[59px] md:py-[41.5px] lg:border-0.5 h-[333px]">
                        <div className="ads h-full md:h-[250px] md:w-[300px]">
                            <AdsComponent/>
                        </div>
                        {/* <p style={{fontSize: '10px'}} className="font-light text-center pt-2 text-gray-300">
                            ADVERTISEMENT
                        </p> */}
                    </div>
                </div>
            ) : (
                <div className={`grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-4`}>
                    <div
                        className={`flex ${index2 % 2 === 1 ? "order-first" : "order-first md:order-last"} flex-col xs:col-span-12 lg:col-span-12 md:col-span-12 gap-y-6`}>
                        <div
                            className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1.1rem] gap-y-5">
                            {data[item].map((station, index) => {
                                if (index < 18) {
                                    return (
                                        <SingleRadio index={index} station={station}/>
                                    )
                                } else return null
                            })}
                            <div className='flex w-full only-show-lg'>
                                <div className="w-1/6 ">
                                    <div className='mb-5'>
                                        {data[item][18] && (
                                            <SingleRadio index={18} station={data[item][18]}/>
                                        )}
                                    </div>
                                    <div>
                                        {data[item][19] && (
                                            <SingleRadio index={19} station={data[item][19]}/>
                                        )}
                                    </div>
                                </div>
                                <div className="w-4/6 flex justify-end flex-col items-center">
                                    <div className="adh h-[90px] w-[728px]" style={{display:"flex", justifyContent:'center'}}>
                                        <AdsHorizontalComponent/>
                                    </div>
                                    {/*<div style={{fontSize: '10px'}}*/}
                                    {/*     className='w-[728px] font-light text-center pt-2 text-gray-300'>ADVERTISEMENT*/}
                                    {/*</div>*/}
                                </div>
                                <div className="w-1/6">
                                    <div className='mb-5'>
                                        {data[item][20] && (
                                            <SingleRadio index={20} station={data[item][20]}/>
                                        )}
                                    </div>
                                    <div>
                                        {data[item][21] && (
                                            <SingleRadio index={21} station={data[item][21]}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center md:justify-end text-red-500 hover:cursor-pointer">
                            <Link to={`/stations/${item}`} className="flex items-center gap-x-2">
                                <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">See
                                    More</p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     className="w-4 h-4 text-purple-500">
                                    <path fillRule="evenodd"
                                          d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                          clipRule="evenodd"/>
                                    <path fillRule="evenodd"
                                          d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                          clipRule="evenodd"/>
                                </svg>
                            </Link>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
}

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import fb from '../assets/image/fb.svg';
import google from '../assets/image/google.svg';
import registration from '../assets/image/registration.png';
import shapeleft from '../assets/image/shapeleft.svg';
import shaperight from '../assets/image/shaperight.svg';
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";
import SocialLogin from "./SocialLogin";

function Registration() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });


  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie('csrftoken');





  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.firstName === "") {
      alert("Please Enter First Name");
      return;
    }
    if (formData.lastName === "") {
      alert("Please Enter Last Name");
      return;
    }
    if (formData.email === "") {
      alert("Please Enter Email");
      return;
    }
    if (formData.password === "") {
      alert("Please Enter Password");
      return;
    }
    if (formData.password2 === "") {
      alert("Please Enter Confirm Password");
      return;
    }
    if (formData.password !== formData.password2) {
      alert("Password and Confirm Password not matched");
      return;
    }

    setLoading(true);
    fetch(`${BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.error) {
          alert(data.error);
        } else if (data.token) {
          alert("Registration Successfull");
          localStorage.setItem("radio-token", data.token);
          navigate("/");
        }
      })
      .catch((err) => {
        setLoading(false);
        alert("Something went wrong");
      });
  };


  return (
    <div className="md:relative font-DM_Sans">
      <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <div className="lg:container lg:mx-auto">
        <div className="px-2 md:pt-[120px] flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row justify-around items-center">
          <div className="flex-1 px-5 z-10">
            <form className="flex justify-center md:w-10/12">
              <div class="flex flex-col items-center w-full py-2 px-3 rounded-2xl mb-4 ">
                <div className="flex flex-col md:py-4 justify-start w-full">
                  <span className="cursor-pointer font-medium text-base text-[#4E97A9]">
                    <Link to="/login">{`<`} Back</Link>
                  </span>
                  <p className="font-Poppins font-bold text-[#253B75] text-base md:text-4xl">Create your free account</p>
                </div>
                <p className="font-DM_Sans font-medium text-[#253B75] text-base md:text-2xl py-3">100% free, No Credit Card needed</p>
                <SocialLogin/>
                <div className="pb-4">OR</div>
                <div class="flex justify-between items-center border-none w-full py-2 gap-x-4 rounded-md mb-4 ">
                  <div className="flex flex-col w-6/12">
                    <input
                      required
                      class="outline-none border-b-2 pl-4 w-full"
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="FIRST NAME"
                      onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                      value={formData.firstName}
                    />
                  </div>
                  <div className="flex flex-col w-6/12">
                    <input
                      required
                      class="outline-none border-b-2 pl-4 w-full"
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="LAST NAME"
                      onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                      value={formData.lastName}
                    />
                  </div>
                </div>
                <div class="flex justify-between items-center border-none w-full py-2 gap-x-4 rounded-md mb-4 ">
                  <div className="flex flex-col w-full">
                    <input
                      required
                      class="outline-none border-b-2 pl-4 w-full"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="EMAIL ADDRESS"
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      value={formData.email}
                    />
                  </div>
                </div>
                <div class="flex justify-between items-center border-none w-full py-2 gap-x-4 rounded-md mb-4 ">
                  <div className="flex flex-col w-6/12">
                    <input
                      required
                      class="outline-none border-b-2 pl-4 w-full"
                      type="password"
                      name="password1"
                      id="password1"
                      placeholder="PASSWORD"
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      value={formData.password}
                    />
                  </div>
                  <div className="flex flex-col w-6/12">
                    <input
                      required
                      class="outline-none border-b-2 pl-4 w-full"
                      type="password"
                      name="password2"
                      id="password2"
                      placeholder="CONFIRM PASSWORD"
                      onChange={(e) => setFormData({ ...formData, password2: e.target.value })}
                      value={formData.password2}
                    />
                  </div>
                </div>
                <div className="py-4 w-full">
                  {
                    loading ? (
                      <button
                        className="mb-2 bg-emerald-500 w-full hover:bg-emerald-700 text-white font-bold py-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit">
                        Please wait...
                      </button>
                    ) : (
                      <button
                        className="mb-2 w-full flex items-center justify-center gap-x-2 text-white text-center bg-gradient-to-r from-red-700 to-purple-800 font-bold py-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleSubmit} type="button">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                          </svg>

                        Sign Up
                      </button>
                    )
                  }
                  <span className="flex justify-center pt-4">Already have an account? <Link className="hover:text-blue-500 underline decoration-1 ml-2" to={"/login"}>Login</Link></span>
                  
                </div>

              </div>
            </form>
          </div>
          <div className="flex-0 md:flex-1">
            <img src={registration} alt="registration" className="w-[250px] h-[250px] md:w-[500px] md:h-[500px]" />
          </div>
        </div>
        <FooterNev />
      </div>
    </div>
  );
}

export default Registration;

import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import user from "../assets/image/user.jpeg";
import radiogo from "../assets/image/radiogo.svg";
import { BASE_URL } from "./BaseURL";
import Icon from "./Icon";

function Navbar() {
  const userData = React.useContext(UserContext);
  const [isViewSidebar, setisViewSidebar] = useState(false);
  const [input, setInput] = useState("");
  const [showMyAccount, setShowMyAccount] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  const searchHandler = (e) => {
    e.preventDefault();
    navigate(`/stations/${input}`);
  };

  const handleLogout = () => {
    const token = localStorage.getItem("radio-token");
    const url = `${BASE_URL}/logout`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          localStorage.removeItem("radio-token");
          window.location.href = "/";
        }
      });
  };
  return (
    <>
      <nav className="sticky top-0 z-50 opacity-95 bg-gray-50 shadow-sm">
        <div className="container flex justify-between items-center py-2 text-black font-normal">
          <div className="grid grid-cols-12 w-full">
            <div className="col-span-12 md:col-span-3">
              <div className="flex items-center justify-between md:justify-start">
                <div className="md:hidden">
                  <button
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none  dark:text-gray-400 "
                    aria-controls="navbar-default"
                    aria-expanded="false"
                    onClick={() => setisViewSidebar(!isViewSidebar)}
                  >
                    <span class="sr-only">Open main menu</span>
                    <svg
                      class="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="">
                  <NavLink to="/">
                    <img src={radiogo} alt="logo" className="w-[130px] h-[35px]"/>
                    {/* <Icon name="logo" width={130} height={35} /> */}
                  </NavLink>
                </div>
                {userData?.userData?.id ? (
                  <div className="dropdown dropdown-end md:hidden">
                    <div tabindex="0" className="hover:cursor-pointer">
                      <img src={user} alt="user" width={30} height={30} />
                    </div>
                    <ul
                      tabindex="0"
                      className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
                    >
                      <li>
                        <NavLink to={"/account"} className="justify-between">
                          Profile
                          {/* <span className="badge">New</span> */}
                        </NavLink>
                      </li>
                      <li>
                        <span onClick={handleLogout}>Logout</span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <NavLink to={"/login"} title="Login" className="md:hidden">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-red-800">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg> */}
                    <img src={user} alt="user" width={30} height={30} />
                  </NavLink>
                )}
              </div>
            </div>
            <div className="col-span-6 self-center">
              <div className="hidden md:flex items-center justify-center text-sm">
                <NavLink
                  to="/about"
                  className={`font-medium ${
                    location.pathname === "/about" &&
                    "bg-gradient-to-r from-red-700 to-purple-800"
                  } hover:bg-gray-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-[length:100%_3px] bg-no-repeat bg-bottom hover:bg-opacity-50 px-1 mx-2 py-1 rounded-none`}
                >
                  About
                </NavLink>
                <NavLink
                  to="/regions"
                  className={`font-medium ${
                    location.pathname === "/regions" &&
                    "bg-gradient-to-r from-red-700 to-purple-800"
                  } hover:bg-gray-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-[length:100%_3px] bg-no-repeat bg-bottom hover:bg-opacity-50 px-1 mx-2 py-1 rounded-none`}
                >
                  Regions
                </NavLink>
                <NavLink
                  to="/categories"
                  className={`font-medium ${
                    location.pathname === "/categories" &&
                    "bg-gradient-to-r from-red-700 to-purple-800"
                  } hover:bg-gray-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-[length:100%_3px] bg-no-repeat bg-bottom hover:bg-opacity-50 px-1 mx-2 py-1 rounded-none`}
                >
                  Categories
                </NavLink>
                <a
                  href="/contact-us"
                  className={`font-medium ${
                    location.pathname === "/contact-us" &&
                    "bg-gradient-to-r from-red-700 to-purple-800"
                  } hover:bg-gray-200 hover:bg-gradient-to-r from-red-700 to-purple-800 bg-[length:100%_3px] bg-no-repeat bg-bottom hover:bg-opacity-50 px-1 mx-2 py-1 rounded-none`}
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-span-3 flex justify-end">
              <div className="hidden md:flex lg:flex justify-end items-center gap-2">
                <div className="flex border border-red-800 bottom-1 w-2/3 rounded-full p-1 bg-white">
                  <input
                    type="text"
                    className="rounded-full w-4/5 px-2 text-sm focus-visible:outline-none"
                    placeholder="Search Here"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    // check if enter key is pressed
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchHandler(e);
                      }
                    }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-gray-400"
                    onClick={searchHandler}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>
                {userData?.userData?.id ? (
                  <div className="dropdown dropdown-end">
                    <div tabindex="0" className="hover:cursor-pointer">
                      <img src={user} alt="user" width={30} height={30} />
                    </div>
                    <ul
                      tabindex="0"
                      className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
                    >
                      <li>
                        <Link to={"/account"} className="justify-between">
                          Profile
                          {/* <span className="badge">New</span> */}
                        </Link>
                      </li>
                      {/* <li><Link to={"/account"}>Settings</Link></li> */}
                      <li>
                        <span onClick={handleLogout}>Logout</span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <NavLink to={"/login"} title="Login">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-red-800">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg> */}
                    <img src={user} alt="user" width={30} height={30} />
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="flex overflow-hidden relative bg-white">
        {isViewSidebar && (
          <aside
            id="sidebar"
            className="fixed h-full top-0 left-0 flex md:hidden flex-shrink-0 flex-col w-full sm:w-64 transition-width duration-75"
            aria-label="Sidebar"
            style={{ zIndex: "9999" }}
          >
            <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
              <button
                className="self-end mr-2 p-1"
                title="Close"
                onClick={() => setisViewSidebar(!isViewSidebar)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5"
                >
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
              <div className="flex-1 flex flex-col pt-2 pb-4 overflow-y-auto">
                <div className="flex-1 px-3 bg-white divide-y space-y-1">
                  <ul className="space-y-0 pb-2 divide-y-0.5">
                    {/* <li style={{ textAlign: 'right' }}><span style={{ padding: '2px 8px', color: 'gray', cursor: 'pointer', borderRadius: '5px', border: '1px solid gray' }}
                      onClick={() => setisViewSidebar(!isViewSidebar)}>X</span></li> */}
                    <li>
                      <Link
                        to="/"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/categories"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Categories</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/regions"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Regions</span>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="/contact-us"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Contact Us</span>
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">About Us</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/add-station"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Add Station</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/brand-guidline"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Brand</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal"
                        onClick={() => setisViewSidebar(!isViewSidebar)}
                        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                      >
                        <span className="ml-3">Legal Notes</span>
                      </Link>
                    </li>
                    <li>
                      {userData?.userData?.id ? (
                        <span
                          onClick={handleLogout}
                          className="ml-3 text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                        >
                          Logout
                        </span>
                      ) : (
                        <Link
                          to="/login"
                          onClick={() => setisViewSidebar(!isViewSidebar)}
                          className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
                        >
                          <span className="ml-3">login</span>
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        )}
      </div>
    </>
  );
}

export default Navbar;

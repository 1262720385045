import React from 'react'
import { BASE_URL } from './BaseURL'

export default function ForgetPassword() {
  const [email, setEmail] = React.useState('')
  const [otp, setOtp] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [showPasswordForm, setShowPasswordForm] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleEmailSubmit = () => {
    setLoading(true)
    const url = `${BASE_URL}/send-otp`
    const data = {
      email: email,
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        if (data.status === 'success') {
          setShowPasswordForm(true)
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        setLoading(false)
        alert(err.message)
      })
  }

  const resetPassword = () => {
    if (password !== confirmPassword) {
      alert('Password and Confirm Password does not match')
      return
    }
    setLoading(true)
    const url = `${BASE_URL}/reset-password`
    const data = {
      email: email,
      otp: otp,
      password: password,
      password_confirmation: confirmPassword,
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        if (data.status === 'success') {
          alert(data.message)
          window.location.href = '/login'
        } else {
          alert(data.message)
        }
      })
      .catch((err) => {
        setLoading(false)
        alert(err.message)
      })
  }

  return (
    <div style={{minHeight:'80vh'}}>
      <div className='flex justify-center items-center h-full'>
        <div className='w-1/2 mt-5'>
          <h1 className='text-3xl font-bold text-center'>Forget Password</h1>
          {!showPasswordForm ? (
            <form className='mt-5'>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
                Email
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='email'
                type='email'
                placeholder='Enter your email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='flex items-center justify-between'>
              {!loading ? (
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                  type='button'
                  onClick={handleEmailSubmit}
                >
                  Send
                </button>
              ) : (
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                  type='button'
                  disabled
                >
                  Loading...
                </button>
              )}
            </div>
          </form>
          ):(
            <form className='mt-5'>
              <p>
                Please enter the OTP sent to your email address and set a new password.<br/><br/>
              </p>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='otp'>
                Enter OTP
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='otp'
                type='text'
                placeholder='Enter OTP'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
                Enter Password
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='password'
                type='password'
                placeholder='Enter Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='confirmpassword'>
                Confirm Password
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                id='confirmpassword'
                type='password'
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className='flex items-center justify-between'>
              {!loading ? (
                <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                type='button'
                onClick={resetPassword}
              >
                Save
              </button>
              ) : (
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                  type='button'
                  disabled
                >
                  Loading...
                </button>
              )}
            </div>
          </form>
          )}
        </div>
      </div>
    </div>
  )
}

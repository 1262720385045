import React from 'react'
import legal from '../assets/image/legal.png'
import shapeleft from '../assets/image/shapeleft.svg'
import shaperight from '../assets/image/shaperight.svg'
import FooterNev from './FooterNev'
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function About() {

    // smoothly scroll to top on page load
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="md:relative">
            <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <div className="lg:container lg:mx-auto">
                <div className='py-6 px-4 lg:px-0'>
                    <div className='flex py-2 items-start'>
                        <div className='flex-1 space-y-2'>
                            <p className='font-medium w-6/6 text-left text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-relaxed tracking-normal'>Creator, Sell or Collect Digital item your <b className='font-semibold text-3xl lg:text-5xl xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-purple-500'>NFTs</b></p>
                            <p className='font-normal w-5/6 text-justify text-sm lg:text-lg leading-1 pt-3'>
                            Radio Go is a comprehensive platform offering over 2000 Greek radio stations, ranging from pop and rock to traditional music, talk shows, and sports. Our dedicated enthusiasts have traveled across Greece to handpick the most exciting music, news, and entertainment collection to keep your heart thumping and your ears buzzing.
                            </p>
                        </div>
                        <div className='md:flex-1 hidden md:flex justify-center'>
                            <img src={legal} alt="legal" className='h-44' />
                        </div>
                    </div>

                    <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-20'>
                    Whatever your musical preference, Radio Go has something for everyone. From the hottest pop and rock tunes to the most soothing traditional music, from intriguing talk shows to exciting sports events, we've got you covered.
                    
                    We created our website to be extremely user-friendly and designed to play your favorite stations effortlessly without any fretting over its usage.
                    <br/><br/>
                    But that's not all; we've gone above and beyond to give a platform that assists you in discovering new stations that match your preferences. You can browse our huge library of stations and discover new favorites depending on the genres and topics that most interest you. It's like going on a musical adventure, with surprising discoveries around every corner.
                    
                    We recognize the profound impact that music and radio can have beyond just providing a source of entertainment. Our goal at Radio Go is to allow you to foster connections, explore new possibilities, and feel a part of something truly exceptional.
                    <br/><br/>
                    So why not become a community member and let us take you on tour through the dynamic and thrilling world of Greek radio? We look forward to bumping those tunes together!

                    </p>
                    <div className="flex justify-center py-5">
                        <div className="adh w-2/3 h-24 md:block hidden self-center mt-[50px]" style={{display:"flex", justifyContent:'center'}}>
                            <AdsHorizontalComponent/>
                            {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
                        </div>
                    </div>
                </div>
                <FooterNev />
            </div>
        </div>
    )
}

export default About
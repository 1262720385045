import React from 'react';
import { Link, useParams } from "react-router-dom";
import { UserContext } from '../App';
import '../App.css';
import adsize from '../assets/image/adsize.svg';
import info from "../assets/image/info.svg";
import playBtn from "../assets/image/play.svg";
import playbtn from '../assets/image/playbtn.svg';
import player_shape from "../assets/image/player_shape.svg";
import playpush from '../assets/image/playpush.svg';
import station_1 from '../assets/image/station-1.png';
import Audio from './Audio';
import { BASE_URL } from './BaseURL';
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";


function Player() {
    const imageDomain = BASE_URL.replace("gr/api", "gr");
    const userDara = React.useContext(UserContext)
    const { id } = useParams();
    const [station, setStation] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [play, setPlay] = React.useState(true)
    // audio ref
    const audioRef = React.useRef()
    const [volume, setVolume] = React.useState(50)
    const [radio_list, setRadio_list] = React.useState([])
    const [isFavorite, setIsFavorite] = React.useState(false)


    // scroll to top on page load
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        fetch(`${BASE_URL}/player/${id}`)
            .then(res => res.json())
            .then(data => {
                setStation(data.station)
                setRadio_list(data.similar_stations)
                setLoading(false)
            })
    }, [id])

    React.useEffect(() => {
        // check is favorite
        const url = `${BASE_URL}/check-is-favorite/${id}`
        const token = localStorage.getItem('radio-token')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    if (data.isFavorite) {
                        setIsFavorite(true)
                    }
                })
        }
    }, [id])

    React.useEffect(() => {
        if (id) {
            // set id to cookies, expire in 30 day
            const d = new Date();
            d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
            const expires = "expires=" + d.toUTCString();
            // check if there is any cookies named recent_station
            const recent_station = document.cookie.split(';').filter(item => item.trim().startsWith('recent_station'))[0]
            if (!recent_station) {
                const data = [id]
                document.cookie = `recent_station=${JSON.stringify(data)};${expires};path=/`;
            } else {
                // set maximum 10 recent station
                const data = JSON.parse(recent_station.split('=')[1])
                if (data.length >= 10) {
                    data.shift()
                }
                if (!data.includes(id)) {
                    data.push(id)
                }
                document.cookie = `recent_station=${JSON.stringify(data)};${expires};path=/`;
            }
        }
    }, [id])

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume / 100
        }
    }, [volume])


    const togglePlayButton = () => {
        setPlay(!play)
    }

    const handleFavorite = () => {
        let addToFavorite = 1
        if (isFavorite) {
            addToFavorite = 0
        }

        const url = `${BASE_URL}/handle-favorite/${id}/${addToFavorite}`
        const token = localStorage.getItem('radio-token')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    if (data.isFavorite) {
                        setIsFavorite(true)
                    } else {
                        setIsFavorite(false)
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            alert('Please Login First')
        }
    }

    return (
        <div className="md:relative lg:container lg:mx-auto lg:min-h-[100vh]">
            <img
                className="invisible sm:visible absolute top-0 right-0 -z-10"
                src={player_shape}
                alt="player_shape"
            />
            <div className='grid grid-cols-12 py-2'>
                <div className='col-span-12 md:col-start-2 md:col-span-10 lg:col-span-8'>
                    <div className='col-span-12  px-4 md:px-0'>
                        <div className='flex flex-col md:flex-row md:justify-start items-start gap-2'>
                            <div className='flex flex-col w-[200px]'>
                                <div className='h-[200px] w-[200px]'>
                                    <img src={station?.logo?.includes("http://127.0.0.1:8000") ? station.logo.replace("http://127.0.0.1:8000", imageDomain) : station.logo} className='h-[200px] w-[200px]' alt='logo' onError={(e) => { e.target.onerror = null; e.target.src = station_1 }} />
                                </div>
                                <div className='flex flex-row text-center justify-between px-0'>
                                    <p className='flex-1 mr-2 border-0.5 border-[#757575] px-6 mt-1 rounded-full text-sm h-6 bg-[#F6F6F6] text-gray-500'>{station?.city_region?.name}</p>
                                    <p className='border-0.5 border-[#757575] h-6 p-1 mt-1 text-center text-[9px] rounded bg-[#F6F6F6] text-gray-600'>
                                        {station?.frequency ? 'FM' : 'Web'}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-col text-xs md:text-base text-gray-400'>
                                <div className='flex justify-between items-center'>
                                    <p className='text-2xl font-semibold text-gray-600'>{station?.name}</p>
                                    <div onClick={handleFavorite} title='Add To Favourite'>
                                        {isFavorite ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style={{ color: 'red' }} className="w-8 h-8 fill-current ">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style={{ color: 'red' }} className="w-8 h-8 fill-none hover:fill-current">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                            </svg>
                                        )}
                                    </div>
                                </div>
                                <p className='text-red-600'>{station?.category?.name}</p>
                                {/* <p className='text-gray-500'>{station?.tags}</p> */}
                                <p>{station?.address}</p>
                                <p>{station?.city_region?.name}</p>
                                <p>Τηλ: {station?.telephone ? station?.telephone : '-'}</p>
                                <p className='italic'>Tags: {station?.tags}</p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center border-2 border-red-400 px-4 py-2 h-24 md:px-8 md:py-2 lg:px-8 mt-2 text-xs md:text-base rounded-full">
                            <p className='text-red-700'>ON AIR - Now Playing</p>
                            <p>{station?.tags}</p>
                        </div>

                        <div className="border-2 border-red-400 px-4 py-2 h-20 md:px-8 md:py-2 lg:px-8 mt-2 text-xs md:text-base rounded-full">
                            {/* <div class="flex justify-between text-sm text-grey-darker">
                                <p>0:40</p>
                                <p>4:20</p>
                            </div> */}
                            <div className="mt-1 flex items-center justify-between">
                                <div style={{ zIndex: '100' }} className="w-5/6" >
                                    <input type="range" min="1" max="100" className='slider' id="myRange" value={volume} onChange={(e) => { setVolume(e.target.value) }} />
                                </div>
                                <div className='pl-4'>
                                    {/* play */}
                                    {!play ? (
                                        <button onClick={togglePlayButton} title='Play' className=''>
                                            <img src={playbtn} className="w-12 h-12" alt='playbtn' />
                                        </button>
                                    ) : (
                                        <button onClick={togglePlayButton} title='Play' className=''>
                                            <img src={playpush} className="w-12 h-12 " alt='pausebtn' />
                                        </button>
                                    )}


                                    {/* push */}
                                    {/* <button title='Play' className=''>
                                        <img src={playpush} className="w-12 h-12 " />
                                    </button> */}

                                    {station?.streaming_domain ? (
                                        <Audio audioRef={audioRef} src={station?.streaming_domain} play={play} />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className='py-2 text-xs font-light text-gray-100'>
                            <span className='px-2 bg-gradient-to-r from-red-700 to-purple-800 float-right rounded-sm cursor-pointer' onClick={(e) => alert('We are working hard to fix this')}>
                                If you can't listen, please click here  &gt;&gt;</span>
                        </div>
                    </div>
                </div>
                <div className='hidden lg:block col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 md:pl-8'>
                    <div className='border-0.5 border-gray-400 h-[250px] mb-2 flex flex-col items-center gap-y-4'>
                        <p className='text-[#979797] pt-4 font-medium'>Περισσότερες προτάσεις</p>
                        <div className='flex flex-col gap-y-2'>
                            {
                                radio_list.map((station, index) => (
                                    <Link to={`/${station?.secondary_domain?.length ? station.secondary_domain : 'player/' + station.code_number}`} key={index} className={index < 5 ? "visible" : "hidden md:block"}>
                                        <div
                                            key={index}
                                            className="group flex items-center w-[200px] h-[50px] justify-between hover:bg-gradient-to-r from-[#AF0909] to-[#da5151] border border-gray-400 text-black hover:text-white  rounded-full p-[1px]"
                                        >
                                            <img
                                                className="w-[46.2px] h-[46.2px] m-[1px] rounded-full border-0.5"
                                                src={station.logo.includes("http://127.0.0.1:8000") ? station.logo.replace("http://127.0.0.1:8000", imageDomain) : station.logo}
                                                alt="staion-1"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = station_1;
                                                }}
                                            />
                                            <div className="flex flex-col items-start font-400 text-xs">
                                                <p className="font-bold text-xs text-[#757575] group-hover:text-white">
                                                    {station?.name.length > 12
                                                        ? station?.name.slice(0, 12) + "..."
                                                        : station?.name}
                                                </p>
                                                <div className="flex flex-row justify-between text-[9px] mb-[2px] -mt-[4px]">
                                                    <div className="text-[9px] ">
                                                        <p
                                                            className="pb-[2px] text-red-600 font-medium group-hover:text-[#C8C8C8]"
                                                            style={{
                                                                overflowX: "hidden",
                                                                whiteSpace: "nowrap",
                                                                width: "80px",
                                                            }}
                                                        >
                                                            {station?.tags.length > 15
                                                                ? station?.tags.slice(0, 15) + "..."
                                                                : station?.tags}
                                                        </p>
                                                        <div
                                                            className="border-0.5 py-[5px] w-[60.62px] font-Poppins h-[9.77px] text-[#757575] group-hover:text-white text-[9px] border-gray-400 text-center flex items-center justify-center bg-gray-100 group-hover:bg-red-800 rounded-full"

                                                        >
                                                            <p className="text-[9px]">
                                                                {station.city_region?.name.length > 10
                                                                    ? station.city_region["name"].slice(0, 10) + "..."
                                                                    : station.city_region["name"]}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col items-center justify-between">
                                                        <img className="w-3 h-3" src={info} alt="info" />
                                                        <p className="font-medium text-[#757575] group-hover:text-white">{station.frequency ? "FM" : "Web"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <img className="w-8 h-8 mr-1" src={playBtn} alt="staion-1" />
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                    <div className='h-[180px]'>
                        <img src={adsize} alt="adsize" className='h-[180px] w-full' />
                    </div>
                </div>
                <div className="col-span-12 py-2 min-h-[50vh]">
                    <div className='my-2 h-[100px] md:hidden'>
                        <img src={adsize} alt="adsize" className='h-[100px] w-full' />
                    </div>
                    <div className='w-full px-3 md:px-0 flex justify-center mb-5 mt-[50px]'>
                        <div className="adh h-[90px] w-[728px] md:block self-center" style={{display:"flex", justifyContent:'center'}}>
                            <AdsHorizontalComponent/>
                            {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Player
import React from 'react'
import legal from '../assets/image/legal.png'
import shapeleft from '../assets/image/shapeleft.svg'
import shaperight from '../assets/image/shaperight.svg'
import FooterNev from './FooterNev'
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function Legal() {
    return (
        <div className="md:relative">
            <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
            <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
            <div className="lg:container lg:mx-auto">
                <div className='py-6 px-4 lg:px-0'>
                    <div className='flex py-2 items-start'>
                        <div className='flex-1 space-y-2'>
                            <p className='font-medium w-6/6 text-left text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-relaxed tracking-normal'>Creator, Sell or Collect Digital item your <b className='font-semibold text-3xl lg:text-5xl xl:text-6xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-purple-500'>NFTs</b></p>
                            {/* <p className='font-normal w-5/6 text-justify text-sm lg:text-lg leading-1 pt-3'>
                            At Radio Go, we understand that your privacy is important. This Privacy Policy explains how we collect, use, and protect your personal information when you use our radio directory service website.
                            </p> */}
                        </div>
                        <div className='md:flex-1 hidden md:flex justify-center'>
                            <img src={legal} alt="legal" className='h-44' />
                        </div>
                    </div>

                    <h1 className='text-2xl font-bold text-center'>Privacy Policy</h1>
                    <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                        At Radio Go, we understand that your privacy is important. This Privacy Policy explains how we collect, use, and protect your personal information when you use our radio directory service website.
                    </p>
                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>1. Information we collect:</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            When you use Radio Go, we collect information that is necessary to provide you with the best possible experience. This information includes:
                            </p>	
                            <div>
                            <span className='font-bold'>Personal information:</span> When registering for an account with Radio Go, we collect your name, email address, and other contact information. You may also choose to provide additional information, such as your age, gender, or location. We use this information to personalize your experience and to communicate with you about your account.
                            <br/><br/>
                            <span className='font-bold'>Usage information:</span> We collect information about how you use Radio Go, including the stations you listen to, the genres you prefer, and the regions you access. We also collect information about the device you use to access our website, including the type of device, its operating system, and its unique device identifier.
                            <br/><br/>
                            <span className='font-bold'>Cookies and other tracking technologies:</span> We use cookies and other tracking technologies to collect information about your usage of Radio Go. Cookies are small text files stored on your device, allowing us to recognize your device when you return to our website. We use cookies to personalize your experience, improve our website, and track your usage of our services.

                            </div>
                        </div>
                    </div>

                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>2. How We Use Your Information</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            We use the information we collect to provide you with a better experience on Radio Go. Specifically, we use your information to:
                            </p>	
                            <div>
                            <span className='font-bold'>Personalize your experience: </span>We use the information you provide to personalize your experience on Radio Go, including the stations and genres you see and the content we recommend to you.
                            <br/><br/>
                            <span className='font-bold'>Improve our website: </span>We use the information we collect to improve our website and to develop new features and services that will be useful to our users. 
                            <br/><br/>
                            <span className='font-bold'>Communicate with you: </span>We may use your contact information to communicate with you about your account, send you updates about our website, or respond to your requests.
                            <br/><br/>
                            <span className='font-bold'>Advertise to you: </span>We may use the information we collect to show you advertisements that are relevant to your interests. We do not sell your information to third-party advertisers.

                            </div>
                        </div>
                    </div>

                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>3. How we share your information:</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            At Radio Go, we understand the importance of protecting your personal information. We do not sell or rent your personal information to third parties for any purpose. However, we may share your information with the following parties for the purposes outlined above:
                            </p>	
                            <div>
                            <span className='font-bold'>Service providers: </span>We may share your information with third-party service providers who help us provide and improve our service. These service providers may include hosting providers, payment processors, and analytics providers. We ensure that these service providers are bound by contractual obligations to safeguard your personal information and use it only for the purposes we disclose.
                            <br/><br/>
                            <span className='font-bold'>Legal requirements: </span> We may disclose your information to comply with legal requirements, such as a court order or subpoena. We may also disclose your information to law enforcement agencies, regulatory authorities, or other government officials if we believe that such disclosure is necessary to comply with the law, protect our rights, or the safety of our users or others.
                            
                            </div>
                        </div>
                    </div>

                    

                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>4. How We Protect Your Information</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            At Radio Go, we take the security of your information seriously. We use industry-standard security measures to protect your information from unauthorized access, disclosure, or destruction. We also require our employees and service providers to follow strict security protocols when handling your data. 

                            </p>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            However, no system is entirely secure, and we cannot guarantee the absolute security of your personal information. Therefore, we encourage you to take appropriate measures to protect yourself, such as using a strong and unique password, keeping your software up-to-date, and being cautious about phishing scams.

                            </p>
                        </div>
                    </div>

                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>5. Your choices</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            At Radio Go, we believe in providing our users with choices and control over their personal information. As such, we offer various ways for you to manage the information we collect about you.
                            </p>	
                            <div>
                            <span className='font-bold'>Account settings: </span>You can update your account information, such as your name, email address, and communication preferences, at any time by logging into your account on our website. This allows you to control the type of information we collect and how we communicate with you.
                            <br/><br/>
                            <span className='font-bold'>Cookies: </span>We use cookies to enhance your browsing experience on our website. You have the option to manage your cookie preferences through your browser settings. You can choose to accept or reject cookies or set your browser to notify you when a cookie is being sent.
                            <br/><br/>
                            <span className='font-bold'>Opt-out: </span>We respect your choices regarding receiving promotional communications from us. You can opt out of receiving such communications by following the instructions included in the communication, such as clicking on the "unsubscribe" link or contacting us directly. Please note that even if you opt out of receiving promotional communications, we may still send you non-promotional messages related to your use of our service, such as service-related announcements and updates.
                            <br/><br/>
                            It's important to note that certain information, such as technical data and analytics, may be collected automatically through cookies and other tracking technologies. However, you can still control the types of cookies and tracking technologies used on our website through your browser settings.
                            </div>
                        </div>
                    </div>


                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>6. Children's Privacy:</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            Radio Go is not intended for use by children under the age of 13, and we do not knowingly collect or store personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will promptly delete such information from our records.
                            </p>
                        </div>
                    </div>

                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>7. Links to Third-Party Websites</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            Our website may contain links to third-party websites that are not owned or controlled by us. We are not responsible for the privacy practices or content of these third-party websites.

                            </p>
                        </div>
                    </div>

                    <div className='mb-10 px-5'>
                        <h3 className='text-lg font-bold'>8. Changes to this policy:</h3>
                        <div className='px-5'>
                            <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                            We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the revised policy on our website.
                            <br/><br/>
                            If you have any questions or concerns about this privacy policy or our practices, please contact Radio Go at

                            </p>
                        </div>
                    </div>


                    <h1 className='text-2xl font-bold text-center'>Terms and Conditions</h1>
                    <p className='font-normal text-justify leading-relaxed text-sm md:text-lg pb-4 pt-4'>
                    Welcome to Radio Go, a radio directory service that helps you find and listen to radio stations from around the world. By using our service, you agree to these terms and conditions, which constitute a binding agreement between you and Radio Go. Please read them carefully before using our service.
                    </p>
                    <div>
                    <span className='font-bold'>1. Use of Service</span>
                    <br/><br/>
                    1.1 Eligibility: You must be at least 13 years old to use our service. If you are under 18 years old, you may only use our service with the permission and supervision of a parent or guardian.
                    <br/><br/>
                    1.2 License: We grant you a limited, non-exclusive, non-transferable, and revocable license to use our service for personal, non-commercial purposes. You may not use our Service for any illegal or unauthorized purpose.
                    <br/><br/>
                    1.3 Content: You may access and use the content provided on our service only for your personal, non-commercial use. You may not modify, reproduce, distribute, transmit, display, perform, publish, license, create derivative works from, transfer, or sell any content obtained from our service.
                    <br/><br/>
                    1.4 User Conduct: You agree not to use our service to:
                    <ul className='list-disc px-10'>
                        <li>violate any laws, regulations, or third-party rights;</li>
                        <li>harass, threaten, or harm others</li>
                        <li>impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                        <li>interfere with or disrupt our service or servers</li>
                        <li>use any automated means to access our service;</li>
                        <li>engage in any activity that may damage, disable, or overburden our service or servers.</li>
                    </ul>
                    <br/><br/>

                    <span className='font-bold'>2. Privacy Policy</span>
                    <br/><br/>
                    We value your privacy and are committed to protecting your personal information. Our privacy policy explains how we collect, use, and disclose your personal information. By using our service, you agree to our privacy policy. Please visit our Privacy Policy page for detailed information.
                    <br/><br/>
                    <span className='font-bold'>3. Personal Information</span>
                    <br/><br/>
                    3.1 Collection and Use: By using our service, you agree to the collection, use, and sharing of your personal information as described in our Privacy Policy. We may collect information such as your name, email address, IP address, and browsing activity on our service. We may use this information to improve our service, provide personalized content and advertising, and communicate with you about our service.
                    <br/><br/>
                    3.2 Security: We take reasonable measures to protect your personal information from unauthorized access, disclosure, and misuse. However, we cannot guarantee the security of your data, as no method of transmission over the internet or electronic storage is 100% secure.
                    <br/><br/>
                    <span className='font-bold'>4. Intellectual Property</span>
                    <br/><br/>
                    4.1 Ownership: Our service and all content and intellectual property rights related to our service, including but not limited to text, graphics, logos, images, audio, and software, are owned by Radio Go or our licensors. You may not use our service or any content on our service in any manner that infringes upon the intellectual property rights of Radio Go or our licensors.
                    <br/><br/>
                    4.2 Trademarks: "Radio Go" and all related logos and designs are trademarks of Radio Go. You may not use these trademarks in any manner without the prior written consent of Radio Go.
                    <br/><br/>
                    <span className='font-bold'>5. Disclaimer of Warranties</span>
                    <br/><br/>
                    5.1 No Warranties: Our service is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding our service's availability, reliability, or accuracy. We do not warrant that our service will be uninterrupted, error-free, or free of viruses or other harmful components. Your use of our service is at your own risk.
                    <br/><br/>
                    5.2 Third-Party Content: Our service may include content provided by third parties. We do not control or endorse any third-party content and make no warranties regarding the accuracy, completeness, or reliability of such content.
                    <br/><br/>
                    5.3 Limitation of Liability: In no event shall Radio Go or its affiliates, directors, officers, employees, agents, or licensors be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our service. This includes, without limitation, damages for loss of profits, data, use, or other intangible losses, even if we have been advised of the possibility of such damages.
                    <br/><br/>
                    <span className='font-bold'>6. Indemnification</span>
                    <br></br>
                    You agree to indemnify and hold harmless Radio Go and its affiliates, directors, officers, employees, agents, or licensors from any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of our service, your breach of these terms and conditions, or your violation of any law or third-party rights.
                    <br/><br/>
                    <span className='font-bold'>7. Changes to Terms and Conditions</span>
                    <br/><br/>
                    We may modify these terms and conditions at any time by posting the revised version on our service. Your continued use of our service after any such modification constitutes your acceptance of the modified terms and conditions.
                    <br/><br/>
                    <span className='font-bold'>8. Termination</span>
                    <br/><br/>
                    We may terminate your access to our service at any time, with or without cause, without notice to you. Upon termination, you must immediately cease all use of our service.
                    <br/><br/>
                    <span className='font-bold'>9. Governing Law</span>
                    <br/><br/>
                    These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which Radio Go is based. Any legal action or proceeding arising from or in connection with these terms and conditions shall be brought to the courts of such jurisdiction.
                    <br/><br/>
                    <span className='font-bold'>10. Entire Agreement</span>
                    <br/><br/>
                    These terms and conditions constitute the entire agreement between you and Radio Go with respect to your use of our service and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and Radio Go. Any rights not expressly granted herein are reserved.
                    <br/><br/>

                    </div>
                    <div className="flex justify-center py-5">
                        <div className="adh w-2/3 h-24 md:block hidden self-center mt-[50px]" style={{display:"flex", justifyContent:'center'}}>
                            <AdsHorizontalComponent/>
                            {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
                        </div>
                    </div>
                </div>
                <FooterNev />
            </div>
        </div>
    )
}

export default Legal
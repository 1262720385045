import React from 'react'

function Dashboard() {
    return (
        <div className="lg:container lg:mx-auto">
            <h1>Dashboard</h1>
        </div>
    )
}

export default Dashboard
import React from 'react';
import { Link, useParams } from "react-router-dom";
import info from "../assets/image/info.svg";
import play from "../assets/image/play.svg";
import station_1 from '../assets/image/station-1.png';
import { BASE_URL } from './BaseURL';

import lineShape from "../assets/image/line.png";
import shapeleft from "../assets/image/shapeleft.svg";
import shaperight from "../assets/image/shaperight.svg";
import FooterNev from "./FooterNev";
import AdsHorizontalComponent from './radioContainer/AdsHorizontalComponent';

export default function Stations() {
    const imageDomain = BASE_URL.replace("gr/api", "gr");
    const { param } = useParams();
    const [name, setName] = React.useState('Categories')
    const [data, setData] = React.useState([])

    // scroll to top on page load
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    React.useEffect(() => {
        fetch(`${BASE_URL}/stations/${param}`)
            .then(res => res.json())
            .then(data => {
                setData(data.stations)
            })
    }, [param])

    React.useEffect(() => {
        if (param !== 'all') {
            setName(param)
        }
    }, [param])

    return (
        <div className="md:relative">
            <img
                className="invisible sm:visible absolute top-0 right-0 -z-50"
                src={shaperight}
                alt="shapeRight"
            />
            <img
                className="invisible sm:visible absolute top-0 left-0 -z-50"
                src={shapeleft}
                alt="shapeleft"
            />
            <img
                className="invisible sm:visible absolute bottom-0 right-0 -z-50"
                src={shaperight}
                alt="shapeRight"
            />
            <img
                className="invisible sm:visible absolute bottom-0 left-0 -z-50"
                src={shapeleft}
                alt="shapeleft"
            />
            <div className="lg:container lg:mx-auto">
                <div className="relative">

                    <span className='absolute right-0 top-2 font-semibold border-2 hover:bg-gray-300 border-gray-200 px-2 py-1 rounded-md' >

                        <Link className='font-semibold border-1 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800 md:px-2 px-1 py-1 rounded-md' to={-1}>Back</Link>
                    </span>
                    <div className="flex flex-col items-center space-y-6 min-h-[100vh] md:min-h-[70vh] lg:min-h-[78vh]">
                        <p className="text-4xl font-semibold pt-12 pb-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                            {name}
                        </p>
                        <img src={lineShape} alt="line" />
                        {/* <p className="font-normal text-lg md:text-xl text-center md:text-left self-center md:self-start w-[220px] md:w-full border-b border-y-rose-900 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
          {name}
          </p> */}
                        <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-12 gap-5 pt-8">
                            <div className="flex flex-col xs:col-span-12 sm:col-span-1 lg:col-span-12 md:col-span-12">
                                <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1rem]">
                                    {data?.map((item, index) => (
                                        <Link target={'_blank'} to={`/${item?.secondary_domain?.length ? item.secondary_domain : 'player/' + item.code_number}`} key={index} >
                                            <div
                                                className="group flex items-center w-[200px] h-[50px] justify-between hover:bg-gradient-to-r from-[#AF0909] to-[#da5151] border border-gray-300 text-black hover:text-white  rounded-full p-[1px]"
                                            >
                                                <img
                                                    className="w-[46.2px] h-[46.2px] m-[1px] rounded-full border-0.5"
                                                    src={item.logo.includes("http://127.0.0.1:8000") ? item.logo.replace("http://127.0.0.1:8000", imageDomain) : item.logo}
                                                    alt="staion-1"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = station_1;
                                                    }}
                                                />
                                                <div className="flex flex-col items-start font-400 text-xs">
                                                    <p className="font-bold text-xs text-[#757575] group-hover:text-white">
                                                        {item?.name?.length > 12
                                                            ? item?.name.slice(0, 12) + "..."
                                                            : item?.name}
                                                    </p>
                                                    <div className="flex flex-row justify-between text-[9px] mb-[2px] -mt-[4px]">
                                                        <div className="text-[9px] ">
                                                            <p
                                                                className="pb-[2px] text-red-600 font-medium group-hover:text-[#C8C8C8]"
                                                                style={{
                                                                    overflowX: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    width: "80px",
                                                                }}
                                                            >
                                                                {item?.tags.length > 15
                                                                    ? item?.tags.slice(0, 15) + "..."
                                                                    : item?.tags}
                                                            </p>
                                                            <div
                                                                className="border-0.5 py-[5px] w-[60.62px] font-Poppins h-[9.77px] text-[#757575] group-hover:text-white text-[9px] border-slate-200 text-center flex items-center justify-center bg-gray-100 group-hover:bg-red-800 rounded-full"

                                                            >
                                                                <p className="text-[9px]">
                                                                    {item?.city_region?.name?.length > 10
                                                                        ? item.city_region["name"].slice(0, 10) + "..."
                                                                        : item?.city_region?.name ? item.city_region["name"] : "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col items-center justify-between">
                                                            <img className="w-3 h-3" src={info} alt="info" />
                                                            <p className="font-medium text-[#757575] group-hover:text-white">{item.frequency ? "FM" : "Web"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className="w-8 h-8 mr-1" src={play} alt="staion-1" />
                                            </div>
                                        </Link>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div className="adh w-2/3 h-24 md:block hidden self-center" style={{display:"flex", justifyContent:'center'}}>
                            {/*<div className=''><AdsHorizontalComponent/></div>*/}
                            <AdsHorizontalComponent/>
                            {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
                        </div>
                    </div>
                </div>
                <FooterNev />
            </div>
        </div>
    )
}

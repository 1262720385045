import React from "react";
import { Link } from "react-router-dom";
import info from "../assets/image/info.svg";
import lineShape from "../assets/image/line.png";
import play from "../assets/image/play.svg";
import user from "../assets/image/user.svg";
import edit from "../assets/image/edit.svg";
import love from "../assets/image/love.svg";
import shapeleft from "../assets/image/shapeleft.svg";
import shaperight from "../assets/image/shaperight.svg";
import station_1 from "../assets/image/station-1.png";
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";
import fb from "../assets/image/fb.svg";
import google from "../assets/image/google.svg";
import { UserContext } from "../App";


function Profile() {
  const userData = React.useContext(UserContext);
  const [data, setData] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [allRegionsAndCategories, setAllRegionsAndCategories] = React.useState(
    []
  );
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  React.useEffect(() => {
    fetch(`${BASE_URL}/allcategories`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.categories);
        //setStations(data.stations);
      });
  }, []);

  const handleSubmit = () => {
    if (formData.name === "") {
      alert("Please Enter Station Name");
      return;
    }
    if (formData.email === "") {
      alert("Please Enter Email");
      return;
    }
    if (formData.message === "") {
      alert("Please Enter Message");
      return;
    }
  };

  return (
    <div className="col-span-1 md:col-span-8">
      <div class="flex flex-col-reverse md:flex-row justify-between items-center md:items-start">
            <div className="flex flex-col w-8/12 flex-1 gap-y-2">
                <div className="flex flex-col md:flex-row">
                  <label className="w-4/12">Name</label>
                  <p className="font-semibold p-2 bg-gray-100 w-full rounded-md md:w-6/12">{userData?.userData?.first_name? userData?.userData?.first_name : "-"}</p>
                </div>
                <div className="flex flex-col md:flex-row">
                  <label className="w-4/12">Surname</label>
                  <p className="font-semibold p-2 bg-gray-100 w-full rounded-md md:w-6/12">{userData?.userData?.last_name? userData?.userData?.last_name : "-"}</p>
                </div>
                <div className="flex flex-col md:flex-row">
                  <label className="w-4/12">Email</label>
                  <p className="font-semibold p-2 bg-gray-100 w-full rounded-md md:w-6/12">{userData?.userData?.email? userData?.userData?.email : "-"}</p>
                </div>
                <div className="flex flex-col md:flex-row">
                  <label className="w-4/12">Password</label>
                  <p className="font-semibold p-2 bg-gray-100 w-full rounded-md md:w-6/12">********</p>
                </div>
            </div>
              <div className="flex-0">
                <div className="h-[100px] w-[100px] rounded-xl bg-green-200"></div>
              </div>
          </div>
    </div>
  );
}

export default Profile;

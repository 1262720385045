import React from 'react'
import facebook from '../assets/image/facebook.png'
import instagram from '../assets/image/instagram.png'
import favicon from '../assets/image/favicon.ico'
import twitter from '../assets/image/twitter.png'
import lineShape from '../assets/image/line.png'
import AdsHorizontalComponent from "./radioContainer/AdsHorizontalComponent";

function SocialMedia() {
    return (
        <div className='hidden md:flex flex-col items-center space-y-6 pt-12'>
            <p className="text-4xl py-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">Social Media</p>
            <img src={lineShape} alt="line"/>
            <p className="text-center font-DM_Sans italic text-[#757575] font-normal text-[14px]" style={{maxWidth:'500px'}}>
            Hey, let's be social butterflies! Join our flock on social media and stay in the loop on all the juicy deets about your favorite shows, news, and upcoming events.    
            </p>
            <div className="flex justify-center space-x-3">
                <a href='https://www.instagram.com/radiogogreece' target='_blank' rel="noreferrer">
                    <div className='flex flex-col border-2 border-slate-200 rounded-lg'>
                        <img src={instagram} alt="staion-1" className='h-20 rounded-t-lg' />
                        <div className="flex justify-start text-[9px] py-4 px-2 gap-2">
                            <img src={favicon} alt="staion-1" className='w-6 h-6 rounded-full' />
                            <div className='flex flex-col'>
                                <p>RadioGo (Greece)</p>
                                <p>@radiogogreece</p>
                            </div>
                        </div>
                    </div>
                </a>
                <a href='https://www.facebook.com/radiogogreece' target='_blank' rel="noreferrer">
                    <div className='flex flex-col border-2 border-slate-200 rounded-lg'>
                        <img src={facebook} alt="staion-1" className='h-20 rounded-t-lg' />
                        <div className="flex justify-start text-[9px] py-4 px-2 gap-2">
                            <img src={favicon} alt="staion-1" className='w-6 h-6 rounded-full' />
                            <div className='flex flex-col'>
                                <p>RadioGo (Greece)</p>
                                <p>@radiogogreece</p>
                            </div>
                        </div>
                    </div>
                </a>
                <a href='https://twitter.com/radiogogreece' target='_blank' rel="noreferrer">
                    <div className='flex flex-col border-2 border-slate-200 rounded-lg'>
                        <img src={twitter} alt="staion-1" className='h-20 rounded-t-lg' />
                        <div className="flex justify-start text-[9px] py-4 px-2 gap-2">
                            <img src={favicon} alt="staion-1" className='w-6 h-6 rounded-full' />
                            <div className='flex flex-col'>
                                <p>RadioGo (Greece)</p>
                                <p>@radiogogreece</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div className="adh w-2/3 h-24 md:block hidden"  style={{display:"flex", justifyContent:'center'}}>
                <AdsHorizontalComponent/>
                {/* <p className='text-[10px] font-light text-center pt-[98px] text-gray-300'>ADVERTISEMENT</p> */}
            </div>
        </div>
    )
}

export default SocialMedia
import React from "react";
import { Link } from "react-router-dom";
import info from "../assets/image/info.svg";
import lineShape from "../assets/image/line.png";
import play from "../assets/image/play.svg";
import user from "../assets/image/user.svg";
import edit from "../assets/image/edit.svg";
import love from "../assets/image/love.svg";
import shapeleft from "../assets/image/shapeleft.svg";
import shaperight from "../assets/image/shaperight.svg";
import station_1 from "../assets/image/station-1.png";
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";

function Favorite() {
  const imageDomain = BASE_URL.replace("gr/api", "gr");
  const [data, setData] = React.useState([]);
  const [stations, setStations] = React.useState([]);

  React.useEffect(() => {
    fetch(`${BASE_URL}/get-favorites`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Token ${localStorage.getItem("radio-token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.categories);
        setStations(data.stations);
      });
  }, []);

  return (
    <div className="flex flex-col xs:col-span-12 sm:col-span-1 lg:col-span-10 md:col-span-10">
      <div className="flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-[1rem]">
        {stations.length === 0 && 'No Favorite Stations'}
        {stations.map((item, index) => (
          <Link
            to={`/${item?.secondary_domain?.length? item.secondary_domain: 'player/' + item.code_number}`}
            key={index}
            className={index < 5 ? "visible" : "hidden md:block"}
          >
            <div className="group flex items-center w-[200px] h-[50px] justify-between hover:bg-gradient-to-r from-[#AF0909] to-[#da5151] border border-gray-300 text-black hover:text-white  rounded-full p-[1px]">
              <img
                className="w-[46.2px] h-[46.2px] m-[1px] rounded-full border-0.5"
                src={item.logo.includes("http://127.0.0.1:8000") ? item.logo.replace("http://127.0.0.1:8000", imageDomain) : item.logo}
                alt="staion-1"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = station_1;
                }}
              />
              <div className="flex flex-col items-start font-400 text-xs">
                <p className="font-bold text-xs text-[#757575] group-hover:text-white">
                  {item?.name.length > 12
                    ? item?.name.slice(0, 12) + "..."
                    : item?.name}
                </p>
                <div className="flex flex-row justify-between text-[9px] mb-[2px] -mt-[4px]">
                  <div className="text-[9px] ">
                    <p
                      className="pb-[2px] text-red-600 font-medium group-hover:text-[#C8C8C8]"
                      style={{
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        width: "80px",
                      }}
                    >
                      {item?.tags.length > 15
                        ? item?.tags.slice(0, 15) + "..."
                        : item?.tags}
                    </p>
                    <div className="border-0.5 py-[5px] w-[60.62px] font-Poppins h-[9.77px] text-[#757575] group-hover:text-white text-[9px] border-slate-200 text-center flex items-center justify-center bg-gray-100 group-hover:bg-red-800 rounded-full">
                      <p className="text-[9px]">
                        {item.city_region?.name.length > 10
                          ? item.city_region["name"].slice(0, 10) + "..."
                          : item.city_region["name"]}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-between">
                    <img className="w-3 h-3" src={info} alt="info" />
                    <p className="font-medium text-[#757575] group-hover:text-white">
                      {item.frequency ? "FM" : "Web"}
                    </p>
                  </div>
                </div>
              </div>
              <img className="w-8 h-8 mr-1" src={play} alt="staion-1" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Favorite;

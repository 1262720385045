import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import lineShape from "../assets/image/line.png";
import shapeleft from '../assets/image/shapeleft.svg';
import shaperight from '../assets/image/shaperight.svg';
import { BASE_URL } from "./BaseURL";
import FooterNev from "./FooterNev";

function AddStation() {

  const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false);
  const [allRegionsAndCategories, setAllRegionsAndCategories] = React.useState([]);
  const [imageName, setImageName] = React.useState("Upload Logo (only jpg or png)");
  const [formData, setFormData] = React.useState({
    name: "",
    country: "",
    logo: "",
    category: "",
    city_region: "",
    address: "",
    telephone: "",
    tags: "",
    website: "",
    email: "",
    //transmission: "",
    frequency: "",
    streaming_domain: "",
    is_active: false,
  });

  // scroll to top on page load
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    fetch(`${BASE_URL}/allregcat`)
      .then((res) => res.json())
      .then((data) => {
        setAllRegionsAndCategories(data.categoriesAndRegions);
      });
  }, []);

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie('csrftoken');

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await getBase64(file);
    console.log(base64);
    setFormData({ ...formData, logo: base64 });
    setImageName(file.name);
  };

  const handleSubmit = () => {
    if (formData.city_region === "") {
      alert("Please Select Region");
      return;
    }
    if (formData.category === "") {
      alert("Please Select Category");
      return;
    }
    if (formData.name === "") {
      alert("Please Enter Station Name");
      return;
    }
    if (formData.country === "") {
      alert("Please Enter Country");
      return;
    }
    if (formData.logo === "") {
      alert("Please Upload Logo");
      return;
    }
    if (formData.address === "") {
      alert("Please Enter Address");
      return;
    }
    if (formData.telephone === "") {
      alert("Please Enter Telephone");
      return;
    }
    if (formData.tags === "") {
      alert("Please Enter Tags");
      return;
    }

    // remove /api from base url
    const url = BASE_URL.replace("gr/api", "gr");
    fetch(`${url}/input-excel-file/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.message === "success") {
          alert("Station Added Successfully");
          window.location.reload();
        } else {
          alert(data.message);
        }
      });
  };

  function onCapChange(value) {
    setIsCaptchaSuccess(true)
  }


  return (
    <div className="md:relative">
      <img className="invisible sm:visible absolute top-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute top-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <img className="invisible sm:visible absolute bottom-0 right-0 -z-50" src={shaperight} alt="shapeRight" />
      <img className="invisible sm:visible absolute bottom-0 left-0 -z-50" src={shapeleft} alt="shapeleft" />
      <div className="lg:container lg:mx-auto">
        <div className="px-2 lg:px-0">
          <div className="flex flex-col items-center pb-10 space-y-6">
            <p className="text-4xl font-semibold pt-12 pb-2 text-transparent bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
              Add Your Station
            </p>
            <img src={lineShape} alt="line" />
          </div>
          <form className="flex justify-center">
            <div class="flex flex-col w-12/12 md:w-6/12 items-center py-2 px-3 rounded-2xl mb-4 bg-gray-200 ">
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Code/Number<span className="text-red-500">*</span>
                </p>
                <div className="flex items-center text-base font-xs bg-gray-100 outline-none w-full rounded-b-2xl">
                  <div className="p-[6px] rounded-bl-xl drop-shadow-md bg-gray-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20"
                      fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input
                    disabled
                    className="py-2 px-2 mr-2 text-xs font-xs bg-gray-50 bg-transparent outline-none border-none w-full rounded-br-2xl"
                    type="text"
                    name="item"
                    id="item"
                    placeholder="Admin will Input this"
                  />
                </div>
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Name<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Country<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Country"
                  value={formData.country}
                  onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Logo<span className="text-red-500">*</span>
                </p>
                <div className="flex justify-between items-center text-base font-xs bg-gray-50 outline-none w-full rounded-bl-2xl rounded-r-2xl">
                  <label className="text-xs font-xs text-gray-400 pl-2">{imageName}</label>
                  
                  <label htmlFor="logo" className="relative p-[6px] py-2 flex items-center rounded-xl drop-shadow-md bg-gradient-to-r from-red-700 via-red-800 to-fuchsia-900 text-white text-xs">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 pr-1">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                    </svg>
                    Upload
                    <input
                    type="file"
                    name="logo"
                    id="logo"
                    //placeholder="Admin will Input this"
                    onChange={handleImageChange}
                    style={{opacity: 0, minHeight:'50px'}}
                    className="absolute inset-0 w-full h-full cursor-pointer"
                  />
                  </label>
                </div>
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  City/Region<span className="text-red-500">*</span>
                </p>
                <div className="flex">
                  <select
                    required
                    className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                    type="text"
                    name="item"
                    id="item"
                    placeholder="Input City/Region"
                    value={formData.city_region}
                    onChange={(e) => setFormData({ ...formData, city_region: e.target.value })}
                  >
                    <option value="">Select City/Region</option>
                    {allRegionsAndCategories?.map((region, index) => {
                      if (region.type_name === "Region") {
                        return (
                          <option key={index} value={region.name}>{region.name}</option>
                        )
                      } else return null
                    })}
                  </select>
                  {/* <div className="flex flex-col justify-around w-4/12 px-1 items-center text-[5px]">
                  <label>Couldn't find your city/Region?</label>
                  <div onClick={() => setShowModal(true)} className="flex items-center hover:cursor-pointer justify-around max-w-[100px] bg-white mx-1 p-[2px] rounded-2xl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                    </svg>
                    <p>Add New City/Region</p>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Address<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Address"
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Telephone<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Telephone"
                  value={formData.telephone}
                  onChange={(e) => setFormData({ ...formData, telephone: e.target.value })}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Category<span className="text-red-500">*</span>
                </p>
                <select
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Style"
                  value={formData.category}
                  onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                >
                  <option value="">Select Category</option>
                  {allRegionsAndCategories?.map((category, index) => {
                    if (category.type_name === "Category") {
                      return (
                        <option value={category.name}>{category.name}</option>
                      )
                    } else return null
                  })}
                </select>
              </div>
              {/* <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Transmission<span className="text-red-500">*</span>
                </p>
                <select
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="transmission"
                  id="transmission"
                  placeholder="Input Transmission"
                  value={formData.transmission}
                  onChange={(e) => setFormData({ ...formData, transmission: e.target.value })}
                >
                  <option value="">Select Transmission</option>
                  <option value="FM_WEB"> FM & Internet</option>
                  <option value="WEB">Internet only</option>
                </select>
              </div> */}
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Frequency<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Frequency"
                />
              </div>
              {/* <div className="w-10/12 md:w-8/12 py-2">
              <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                Style<span className="text-red-500">*</span>
              </p>
              <input
                required
                className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                type="text"
                name="item"
                id="item"
                placeholder="Input Style"
                onChange={(e) => setFormData({ ...formData, style: e.target.value })}
                value={formData.style}
              />
            </div> */}
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Tags<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Tags"
                  onChange={(e) => setFormData({ ...formData, tags: e.target.value })}
                  value={formData.tags}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Streaming Domain<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Input Streaming Domain"
                  onChange={(e) => setFormData({ ...formData, streaming_domain: e.target.value })}
                  value={formData.streaming_domain}
                />
              </div>
              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Website<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="text"
                  name="item"
                  id="item"
                  placeholder="Example: www.yoursite.com"
                  onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                  value={formData.website}
                />
              </div>

              <div className="w-10/12 md:w-8/12 py-2">
                <p className="text-base font-normal py-1 text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800">
                  Email<span className="text-red-500">*</span>
                </p>
                <input
                  required
                  className="py-2 px-2 text-xs font-xs bg-gray-50 outline-none w-full rounded-b-2xl"
                  type="email"
                  name="item"
                  id="item"
                  placeholder="Input your valid email"
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  value={formData.email}
                />
              </div>
              <br />
              <ReCAPTCHA
                sitekey={'6Ld2IZokAAAAAKYBd1X3vL17oEHWvg9Y7vuvUgSs'}
                onChange={onCapChange}
              />
              <div className="w-10/12 md:w-8/12 py-2 flex justify-center">
                <button disabled={!isCaptchaSuccessful} onClick={handleSubmit} type="button" className="text-base font-light py-1 text-white text-center bg-gradient-to-r from-red-700 to-purple-800 rounded-md p-2">
                  Add Station
                </button>
              </div>
            </div>
          </form>
        </div>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
                  {/*body*/}
                  <div className="relative p-6 flex flex-col w-full h-80">
                    <p className="text-3xl font-medium py-4 text-transparent text-center bg-clip-text bg-gradient-to-r  from-red-700 to-purple-600">Add City/Region</p>
                    <div className="flex flex-col items-center gap-2 bg-gray-200 px-6 py-6">
                      <div class="flex justify-center gap-4 m-3 w-80">
                        <div class="form-check form-check-inlineg">
                          <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="category" />
                          <label class="form-check-label inline-block text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800" for="inlineRadio10">Category</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                          <label class="form-check-label inline-block text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800" for="inlineRadio20">Region</label>
                        </div>
                      </div>
                      <div class="form-group flex flex-col items-center justify-center">
                        <label class="inline-block text-transparent text-center bg-clip-text bg-gradient-to-r from-red-700 to-purple-800" for="name">Name</label>
                        <input className="rounded-b-lg py-2 px-2" type="text" name="inlineRadioOptions" id="name" />
                      </div>
                      <button className="px-4 py-2 text-center w-[80px] rounded-full text-white bg-gradient-to-r  from-red-700 to-purple-600">Add</button>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-1 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        <FooterNev />
      </div>
    </div>
  );
}

export default AddStation;